<template>
  <div class="userAgreement">
    <h2>用户协议</h2>
    <div class="txt">
      <h3>艺术志愿填报系统服务协议</h3>
      <p>
        欢迎您购买本公司（微信小程序的运营方）提供的“艺术志愿填报系统服务”。以下内容为《艺术志愿填报系统服务协议》（以下简称“本协议”），在您确认购买“艺术志愿填报系统服务”（以下简称“本服务”）和支付费用前，请您务必审慎阅读、充分理解本协议中相关条款内容。
      </p>
      <p class="black">重要须知：</p>
      <p class="black">
        1.在您确认购买本服务前，请您务必审慎阅读、充分理解本协议的相关条款内容。若您不同意本协议或其中任何条款的约定，您应立即停止付费手续。
      </p>
      <p>
        2.
        <span class="black"
          >未成年人用户请在父母或监护人陪同下审阅本协议，在确认购买服务并付费前取得父母或监护人的同意。</span
        >未成年人购买本协议服务的行为视为已获得其监护人的认可。
      </p>
      <p>
        3.请您注意本协议中的全部条款，仔细阅读并考虑风险。为容易辨析，<span
          class="black"
          >免除或者限制本公司责任的条款、法律适用和争议解决条款等重要条款将以粗体标识</span
        >，您应重点阅读。
      </p>
      <p>
        如您对本协议有任何疑问，可向本公司客服人员（小程序端“反馈与投诉”模块）咨询。
      </p>
      <p class="black">1.协议成立及生效</p>
      <p>
        <span class="black"
          >1.1当您点击“支付”按钮，即表示您已充分阅读、理解、完全接受本协议的全部内容，</span
        >同意购买本公司提供的“艺术志愿填报系统服务”并与本公司订立本协议，本协议自您点击“支付”按钮之时起成立。
      </p>
      <p>
        1.2本协议成立后，您应当按照程序指引，完成服务费支付手续。若您超过30分钟未付费，本协议自动解除，但您可以通过再次点击“同意”重新签署本协议。
      </p>
      <p>
        1.3当您成功支付服务费后，本协议生效。<span class="black"
          >本协议生效后，除本协议另有约定或法律规定的原因外，任何一方均不得单方面解除、终止本协议。</span
        >
      </p>
      <p class="black">2.服务内容及收费标准</p>
      <p class="black">
        2.1本协议项下“艺术志愿填报系统服务”是指本公司通过优艺升-弈升生涯艺术版官方网站及微信小程序（以下简称“优艺升-弈升生涯艺术版平台”）向购买本服务的用户提供的专享服务，具体服务内容、服务价格根据服务版本不同而有所差异，具体以您点击“支付”时，优艺升-弈升生涯艺术版平台相应版本的服务介绍页面显示的为准。
      </p>
      <p>
        2.2当您根据本协议第1条完成付费手续后，本公司将根据您提供的相关信息（包括用户个人信息及关联方相关信息）及平台规则，为您开通相应版本的功能使用权限。
      </p>
      <p class="black">3.服务期限</p>
      <p>
        3.1本协议有效期自您完全支付服务费之日起至当年的8月31日止（8月31日以后购买，有效期至次年8月31日）
      </p>
      <p class="black">
        3.2若您未在协议有效期内享受本协议服务内容，即视为您自愿放弃相关权利，本公司概不退还您已支付的服务费用。
      </p>
      <p class="black">4.权利和义务</p>
      <p class="black">
        4.1为向您提供本协议约定服务之目的，您授权本公司收集、存储、传输、分析和使用您提供或本公司通过其他正当渠道搜集的个人信息，包括但不限于：
      </p>
      <p>
        （1）个人身份信息：如姓名、性别、年龄、出生日期、证件类型、证件号码等；
      </p>
      <p>
        （2）个人网络信息：使用Cookies、IP地址和其它技术方式收集您的非个人化信息；
      </p>
      <p>（3）其他本公司认为为甲方提供相关服务所必须收集的个人信息。</p>
      <p class="black">
        4.2为确保服务质量，您应确保您通过优艺升-弈升生涯艺术版平台向本公司提供的本项服务所需的各项信息均真实、准确、有效、完整，任何因非法、不真实、不准确、过时、虚假的用户信息所产生的责任由您承担。
      </p>
      <p class="black">
        4.3本公司向您提供的某些服务需由本公司的关联方或合作方（包括但不限于提供相关技术服务商、信息服务商、教育合作机构或其他业务合作方）提供或共同提供，您授权本公司将有关个人信息与该等关联方或合作方共享。
      </p>
      <p class="black">
        4.4您应妥善保管用户账户、密码等个人信息，不得以出租、转借或以其他方式将申请本协议项下的服务过程中的任何上述信息交由其他任何第三方使用，任何使用用户上述信息申请本协议项下服务的均被本公司视为用户操作且不可撤回或撤销。如您发现有他人冒用、盗用或有其他可能危及到用户账户资金安全情形时，应立即通知本公司，向本公司申请暂停相关服务。非因本公司的过错导致的任何损失由您自行承担。
      </p>
      <p class="black">
        4.5您应合理使用其享有的艺术志愿填报系统服务权益，除为实现您自身的艺术志愿填报系统服务利益外，不得利用您享有的服务非法获利，不得以任何形式售卖您所享有的服务，不得滥用您所享有的服务。如本公司合理怀疑的或判定的您存在前述不当行为，本公司有权暂停或关闭您的艺术志愿填报系统服务资格或用户账号，此外，您需承担因此引起的相应责任及后果。
      </p>
      <p>4.6您同意接受本协议即表明您就本协议的有关事项作出以下承诺与保证：</p>
      <p>
        （1）您具备完全民事行为能力或虽不具备完全民事行为能力，但签订、履行本协议与其年龄、智力相适应并经您的监护人同意；
      </p>
      <p>
        （2）您提供的各项信息及资料真实、准确、完整，不存在任何方式伪造或篡改的情况，亦不存在有意或无意对本公司隐瞒相关信息的情况。
      </p>
      <p>
        4.7本公司对您提供的个人信息、资料承担保密义务，未经您的许可，本公司承诺不会向第三方提供（披露）前述信息资料与文件，<span
          class="black"
          >但以下特定情形除外：</span
        >
      </p>
      <p class="black">
        （1）本公司根据法律法规或有权机关的指示提供用户的个人信息；
      </p>
      <p class="black">
        （2）本公司为履行本协议向合作单位披露您的个人信息，但应确保合作单位就您的个人信息承担保密义务；
      </p>
      <p class="black">
        （3）由于黑客攻击、电脑病毒侵入、恶意程序及其他不可抗力事件、意外事件导致用户个人信息泄露；
      </p>
      <p class="black">（4）您自行向第三方公开个人信息；</p>
      <p class="black">
        （5）由于您将账户密码告知他人或其他非因本公司及其合作单位的原因导致您的个人信息泄露；
      </p>
      <p class="black">5.知识产权</p>
      <p>
        5.1非经本公司书面同意，您不得以包括但不限于使用、修改、复制、公开传播、篡改等在内的非法形式侵犯本公司及其各关联企业相应的商标、专利、著作权及其他知识产权。
      </p>
      <p>
        5.2本公司为提供本服务之目的向您提供的信息资料、分析结果等内容仅限于您报考院校之目的使用，未经本公司同意，您不得对该等信息资料进行公开传播、转载、商业利用或进行任何衍生利用。
      </p>
      <p>
        5.3优艺升-弈升生涯艺术版相关知识产权（包括但不限于商标、专利及著作权等）归本公司或其关联方所有，非经本公司事先书面同意，您不得使用、修改、复制、公开传播、篡改或以其他方式侵犯优艺升-弈升生涯艺术版知识产权。
      </p>
      <p class="black">6. 退款</p>
      <p class="black">
        出现下列情形之一的，您有权要求本公司退还您已支付的会员服务费用：
      </p>
      <p>
        （1）产品服务无法正常使用：因优艺升-弈升生涯艺术版平台系统故障等原因（符合本协议第7条约定的免责情形除外），导致您在高考当年志愿填报期间无法正常使用优艺升-弈升生涯艺术版服务，影响您填报志愿的；
      </p>
      <p class="black">7.免责条款</p>
      <p class="black">
        7.1优艺升-弈升生涯艺术版平台出现下列任一状况而无法正常运作，致使无法向您提供本协议项下服务，本公司不承担任何违约或赔偿责任：
      </p>
      <p class="black">（1）在服务平台维护期间；</p>
      <p class="black">（2）电信设备出现故障不能进行数据传输的；</p>
      <p class="black">
        （3）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成系统障碍不能执行业务的；
      </p>
      <p class="black">
        （4）由于黑客攻击、电信部门和其他平台有信息技术依赖的相关部门、企事业单位技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。
      </p>
      <p class="black">
        7.2因国家相关主管部门颁布、变更的法令、政策导致本公司不能提供本协议项下服务的，不视为本公司违约，本公司与您可根据相关的法令、政策变更条款内容或提前终止本协议。
      </p>
      <p>7.3您同意接受本协议即表明您理解并认可：</p>
      <p class="black">
        （1）本公司根据本协议提供的服务均系基于您提供的信息、您作出的陈述与说明以及届时有效的院校报考通知及高考录取的政策法规作出，若您提供的信息或陈述的事实存在虚假、欺瞒、错误、遗漏等情形，将影响服务结果及有关建议的准确性、可靠性，若因此导致您出现掉档、退档、专业调剂、未被志愿学校、专业录取等后果由您自行承担，本公司不予任何形式的赔偿。
      </p>
      <p class="black">
        （2）本公司根据本协议向您提供的院校数据、招录信息、考试院的投档数据、分析及咨询意见、志愿填报方案建议均仅供您参考，最终高考填报方案您有权自行决定。您理解，即使您完全依据咨询建议填报志愿方案仍然存在一定程度上掉档、退档、被调剂、未被理想学校、专业录取的风险，因此您应审慎考虑各种风险因素后最终确认填报方案。
      </p>
      <p class="black">8.协议变更与解除</p>
      <p class="black">
        8.1本公司有权因国家法律法规变化及网站运营需要不定期地拟定、修改本协议，并及时以公告的方式通知您。经修订的条款一经通知，立即自动生效，您应及时关注发布的各项服务规则及本协议的变更。
      </p>
      <p class="black">
        8.2本公司有权根据风险及自身业务运营情况的需要，中止（终止）向您提供服务，因此导致您无法使用服务或服务受到限制的，不视为本公司违约。本公司拟终止提供服务的，应当至少提前三个月在其主页面醒目位置以公告形式予以通知。
      </p>
      <p class="black">9.其他</p>
      <p>
        9.1如果本协议任意条款规定根据任何法律或法规在任何方面被裁定为无效、不合法或不可强制执行，本协议其余规定不应在任何方面受到影响或损害。您应与本公司通过诚意磋商，争取以法律许可以及双方期望的最大限度内有效的规定取代该等无效、不合法或不可强制执行的规定。
      </p>
      <p>9.2本协议最终解释权归本公司所有。</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.userAgreement {
  width: 1280px;
  /* height: 100vh; */
  background: #f7f7f7;
  margin: 30px auto;
  h2 {
    margin-bottom: 20px;
  }
  h3 {
    font-family: 黑体;
    font-size: 21px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 30px;
  }
  .txt {
    width: 880px;
    padding: 30px 200px;
    background: #ffffff;

    p {
      font-family: 宋体;
      text-indent: 2em;
      margin-bottom: 15px;
      line-height: 150%;

      .black {
        color: #0d0d0d;
        font-weight: bold;
      }
    }

    .black {
      color: #0d0d0d;
      font-weight: bold;
    }
  }
}
</style>