<template>
  <div class="perfect">
    <div class="perfect-box">
      <div class="centent">
        <div class="title">完善信息</div>
        <i class="el-icon-close" @click="close()"></i>
        <div class="form">
          <div class="form-li">
            <div class="left">
              <div>*</div>
              省份
            </div>
            <div class="main">
              <el-select
                v-model="studentList.province"
                placeholder="请选择高考省份"
                @change="provinceChange(studentList.province)"
              >
                <el-option
                  v-for="item in options"
                  :key="item.recordId"
                  :label="item.abbrName"
                  :value="item.recordId"
                  >{{ item.abbrName }}</el-option
                >
              </el-select>
            </div>
            <div class="right">提交后不可修改</div>
          </div>
          <div class="form-li">
            <div class="left">
              <div>*</div>
              高考年份
            </div>
            <div class="main">
              <el-select
                v-model="studentList.yearly"
                placeholder="请选择高考年份"
              >
                <el-option
                  v-for="item in yearlyList"
                  :key="item.recordId"
                  :label="item.yearName"
                  :value="item.recordId"
                  >{{ item.yearName }}</el-option
                >
              </el-select>
            </div>
            <div class="right">提交后不可修改</div>
          </div>
          <div class="form-li">
            <div class="left">
              <div>*</div>
              艺考方向
            </div>
            <div class="main">
              <el-cascader
                placeholder="请选择艺考方向"
                v-model="artexam"
                :options="artList"
                :props="{
                  expandTrigger: 'hover',
                  value: 'recordId',
                  label: 'artName',
                  checkStrictly: false,
                  emitPath: true,
                  children: 'children',
                }"
                @change="handleChange"
              ></el-cascader>
            </div>
            <div class="right"></div>
          </div>
          <div class="form-li">
            <div class="left">
              <div>*</div>
              首选科目
            </div>
            <div class="main">
              <el-radio
                v-model="studentList.firstMajor"
                :key="item.index"
                :label="item.recordId"
                v-for="item in this.firstMajorList"
                >{{ item.subjectName }}</el-radio
              >
            </div>
            <div class="right"></div>
          </div>
          <div class="form-li">
            <div class="left">
              <div>*</div>
              再选科目
            </div>
            <div class="main" style="min-width: 300px">
              <el-checkbox-group
                v-model="studentList.otherMajor"
                :min="0"
                :max="2"
              >
                <el-checkbox
                  v-for="city in this.otherMajorList"
                  :label="city.recordId"
                  :value="city.recordId"
                  :key="city.subjectName"
                  >{{ city.subjectName }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="right"></div>
          </div>
          <div class="form-li">
            <div class="left">
              <div>*</div>
              高中
            </div>
            <div class="main">
              <el-input
                v-model="studentList.school"
                placeholder="请输入高中名字"
              ></el-input>
            </div>
            <div class="right"></div>
          </div>
          <div class="form-li">
            <div class="left">
              <div>*</div>
              性别
            </div>
            <div class="main">
              <el-radio
                v-model="studentList.userSex"
                :key="item.recordId"
                :label="item.recordId"
                v-for="item in this.sexList"
                >{{ item.enumName }}</el-radio
              >
            </div>
            <div class="right"></div>
          </div>
          <div class="form-li">
            <div class="left">
              <div>*</div>
              名字
            </div>
            <div class="main">
              <el-input
                v-model="studentList.userName"
                placeholder="请输入名字"
              ></el-input>
            </div>
            <div class="right"></div>
          </div>
          <div class="form-li">
            <div class="left">
              <div>*</div>
              语文成绩
            </div>
            <div class="main">
              <el-input
                v-model="studentList.ywScore"
                placeholder="请输入语文成绩，最高成绩为150分"
                type="number"
                @focus="studentList.ywScore = ''"
                @blur="toFix(studentList.ywScore, 'ywScore')"
                title=""
                :controls="false"
                class="hide-number-buttons"
              />
            </div>
          </div>
          <div class="form-li">
            <div class="left">
              <div>*</div>
              英语成绩
            </div>
            <div class="main">
              <el-input
                v-model="studentList.enScore"
                placeholder="请输入英语成绩，最高成绩为150分"
                type="number"
                @focus="studentList.enScore = ''"
                @blur="toFix(studentList.enScore, 'enScore')"
                title=""
                :controls="false"
                class="hide-number-buttons"
              />
            </div>
          </div>
          <div class="form-li">
            <div class="left">
              <div>*</div>
              数学成绩
            </div>
            <div class="main">
              <el-input
                v-model="studentList.sxScore"
                placeholder="请输入数学成绩，最高成绩为150分"
                type="number"
                @focus="studentList.sxScore = ''"
                @blur="toFix(studentList.sxScore, 'sxScore')"
                title=""
                :controls="false"
                class="hide-number-buttons"
              />
            </div>
          </div>
          <div class="form-li" v-if="this.firstMajorName">
            <div class="left">
              <div>*</div>
              首选<span>({{ this.firstMajorName }})</span> 成绩
            </div>
            <div class="main">
              <el-input
                v-model="studentList.firstScore"
                placeholder="请输入科目成绩，最高成绩为100分"
                type="number"
                @focus="studentList.firstScore = ''"
                @blur="toFix(studentList.firstScore, 'firstScore')"
                title=""
                :controls="false"
                class="hide-number-buttons"
              />
            </div>
          </div>
          <div class="form-li" v-if="studentList.otherMajor.length > 1">
            <div class="left">
              <div>*</div>
              次选({{ otherMajorName[0] }})成绩
            </div>
            <div class="main">
              <el-input
                v-model="studentList.otherScoreOne"
                placeholder="请输入科目成绩，最高成绩为100分"
                type="number"
                @focus="studentList.otherScoreOne = ''"
                @blur="toFix(studentList.otherScoreOne, 'otherScoreOne')"
                title=""
                :controls="false"
                class="hide-number-buttons"
              />
            </div>
          </div>
          <div class="form-li" v-if="studentList.otherMajor.length > 1">
            <div class="left">
              <div>*</div>
              次选({{ otherMajorName[1] }})成绩
            </div>
            <div class="main">
              <el-input
                title=""
                v-model="studentList.otherScoreTwo"
                placeholder="请输入科目成绩，最高成绩为100分"
                type="number"
                @focus="studentList.otherScoreTwo = ''"
                @blur="toFix(studentList.otherScoreTwo, 'otherScoreTwo')"
                :controls="false"
                class="hide-number-buttons"
              />
            </div>
          </div>
          <div class="form-li">
            <div class="left">
              <div>*</div>
              文化成绩
            </div>
            <div class="main">
              <el-input
                placeholder="请输入文化成绩，最高成绩为750分"
                :value="cultureScoreNum"
                v-model="studentList.cultureScore"
                disabled
                type="number"
               
                @blur="blueEvent(1)"
                :controls="false"
                class="hide-number-buttons"
              ></el-input>
            </div>
            <div class="right"></div>
          </div>
          <div class="form-li">
            <div class="left">
              <div>*</div>
              统考成绩
            </div>
            <div class="main">
              <el-input
                v-model="studentList.majorScore"
                placeholder="请输入统考成绩，最高成绩为300分"
                type="number"
                title=""
                @focus="studentList.majorScore = ''"
                @blur="toFix(studentList.majorScore, 'majorScore')"
                :controls="false"
                class="hide-number-buttons"
              ></el-input>
            </div>
            <div class="right"></div>
          </div>
        </div>
        <div class="button">
          <el-button @click="submit" :disabled="submitting">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  province,
  yearly,
  artexam,
  subject,
  getdictNbr,
  studentEdit,
  getuserlist,
} from "../../../api/index";
export default {
  name: "perfect",
  data() {
    return {
      // 省份列表
      options: [],
      //   省份
      province: "",
      yearlyList: [],
      yearly: "",
      artList: [],
      artexam: [],
      firstMajors: "",
      firstMajorList: [],

      otherMajor: [],
      otherMajorList: [],

      sexList: [],
      sex: "",
      name: "",
      highSchool: "",
      submitting: false,
      majorScore: "",
      cultureScore: "",

      studentList: {
        otherMajor: [],
      },
      firstMajorName: "",
      otherMajorName: [],
    };
  },
  computed: {
    cultureScoreNum() {
      return (this.studentList.cultureScore = (
        parseFloat(this.studentList.ywScore) +
        parseFloat(this.studentList.enScore) +
        parseFloat(this.studentList.sxScore) +
        parseFloat(this.studentList.firstScore) +
        parseFloat(this.studentList.otherScoreOne) +
        parseFloat(this.studentList.otherScoreTwo)
      ).toFixed(2));
    },
  },

  watch: {
    "studentList.firstMajor": {
      handler(newVal, oldVal) {
        console.log("firstMajor", newVal, oldVal);
        this.firstMajorList.forEach((item) => {
          if (item.recordId == newVal) {
            this.firstMajorName = item.subjectName;
            if (oldVal != undefined) {
              this.studentList.firstScore = "";
            }
          }
        });
      },
      deep: true,
    },
    "studentList.otherMajor": {
      handler(newVal, oldVal) {
        console.log("otherMajor", newVal, oldVal); // 打印变化前后的值
        // 遍历新值中的每个项
        if (this.studentList.otherMajor.length == 0) {
          this.otherMajorName = [];
          this.studentList.otherScoreOne = "";
          this.studentList.otherScoreTwo = "";
        }
        this.otherMajorList.forEach((item1) => {
          // 如果当前项的recordId与城市列表中的recordId相匹配
          newVal.forEach((item) => {
            // 遍历cities列表
            if (item == item1.recordId) {
              console.log(
                12341214,
                item,
                this.studentList.otherMajor.indexOf(item) // 打印当前项在新值中的索引
              );
              // 更新otherMajorName数组中对应索引的值为科目列表中的subjectName
              // 获取当前项在新值中的索引
              let index_1 = this.studentList.otherMajor.indexOf(item);
              // 更新otherMajorName数组中对应索引的值为科目列表中的subjectName
              this.otherMajorName[index_1] = item1.subjectName;

              if (this.studentList.otherMajor.length == 1) {
                this.otherMajorName.splice(1, 1);
                this.studentList.otherScoreOne = "";
                this.studentList.otherScoreTwo = "";
              }
            }
          });
        });
      },
      deep: true, // 深度监听，确保能够检测到数组中的变化
    },
  },
  mounted() {
    // 禁止滚动条
    document.body.style.overflow = "hidden";
    this.getuserlist();
    this.getProvince();
    this.getSelectList();

    this.getSubjectList();
    this.getdictNbr();
  },
  beforeDestroy() {
    // 恢复滚动条
    document.body.style.overflow = "";
  },
  methods: {
    toFix(data, data1) {
      if (data1 == "majorScore") {
        if (data > 300 || data < 0) {
          this.$notify({
            type: "warning",
            title: "提示",
            message: "统考成绩应在0-300分之间",
            showClose: false,
          });
          this.studentList[data1] = 0;
        } else {
          this.studentList[data1] = Number(data).toFixed(2);
        }
      } else {
        if (data1 == "ywScore" || data1 == "enScore" || data1 == "sxScore") {
          if (data > 150 || data < 0) {
            this.$notify({
              type: "warning",
              title: "提示",
              message: "请输入0-150分之间成绩",
              showClose: false,
            });
            this.studentList[data1] = 0;
          } else {
            // this.studentList[data1] = (Math.round(Number(data) * 2) / 2).toFixed(2);
            if (data % 1 !== 0.5&& data % 1 !== 0) {
              this.$notify({
                type: "warning",
                title: "提示",
                message: "请输入0-150分之间成绩,且小数部分为0.5",
                showClose: false,
              })
              this.studentList[data1] = 0;
            } else {
               this.studentList[data1] = (Math.round(Number(data) * 2) / 2).toFixed(2);
            }
          }
        } else {
          if (data > 100 || data < 0) {
            this.$notify({
              type: "warning",
              title: "提示",
              message: "请输入0-100分之间成绩",
              showClose: false,
            });
            this.studentList[data1] = 0;
          } else {
            if (data % 1 !== 0.5&& data % 1 !== 0) {
              this.$notify({
                type: "warning",
                title: "提示",
                message: "请输入0-100分之间成绩,且小数部分为0.5",
                showClose: false,
              })
              this.studentList[data1] = 0;
            } else {
               this.studentList[data1] = (Math.round(Number(data) * 2) / 2).toFixed(2);
            }
          }
        }
      }

      // this.studentList[data1] = Number(data).toFixed(2);
      // console.log(this.studentList[data1], data1);
    },
    close() {
      console.log(123);
      this.$store.commit("Driver", 0);
    },
    provinceChange(data) {
      this.getArtList(data);
    },
    blueEvent(data) {
      if (data == 1) {
        if (
          this.studentList.cultureScore > 750 ||
          this.studentList.cultureScore < 0
        ) {
          this.$notify({
            type: "warning",
            title: "提示",
            message: "文化成绩应在0-750分之间",
            showClose: false,
          });
          this.studentList.cultureScore = "";
        }
      } else if (data == 2) {
        if (
          this.studentList.majorScore > 300 ||
          this.studentList.majorScore < 0
        ) {
          this.$notify({
            type: "warning",
            title: "提示",
            message: "统考成绩应在0-300分之间",
            showClose: false,
          });
          this.studentList.majorScore = "";
        }
      }
    },
    getuserlist() {
      let uesrId = localStorage.getItem("userId");
      getuserlist(uesrId).then((res) => {
        this.studentList = res.data[0];
        this.getArtList();
      });
    },
    handleChange(value) {
      // console.log(value);
    },
    async submit() {
      if (this.submitting) return;
      this.submitting = true;
      setTimeout(() => {
        this.submitting = false;
      }, 1000);
      if (
        !this.studentList.province ||
        !this.studentList.yearly ||
        this.artexam.length == 0 ||
        !this.studentList.firstMajor ||
        !this.studentList.school ||
        !this.studentList.userSex ||
        !this.studentList.userName ||
        !this.studentList.majorScore ||
        !this.studentList.cultureScore ||
        this.studentList.otherMajor.length !== 2
      ) {
        this.$notify({
          type: "warning",
          title: "提示",
          message: "请填写未完成信息或确保再选科目为两个",
          showClose: false,
        });
        return;
      }
      if (this.artexam.length == 1) {
        console.log("1", this.artexam);
        this.studentList.artWard = this.artexam[0];
        this.studentList.subWard = null;
      } else if (this.artexam.length == 2) {
        console.log("2", this.artexam);
        this.studentList.artWard = this.artexam[0];
        this.studentList.subWard = this.artexam[1];
      }
      this.studentList.isImproveInfo = 1;
      localStorage.setItem("username", this.studentList.userName);
      this.$store.commit("setUserList", this.studentList.userName);
      const province1 = this.options.find(
        (province) => province.recordId === this.studentList.province
      );
      localStorage.setItem(
        "province",
        JSON.stringify({
          id: province1.recordId,
          name: province1.abbrName,
        })
      );
      studentEdit(this.studentList).then((res) => {
        if (res.code == 200) {
          this.$store.commit("Driver", 2);
          this.$notify({
            type: "success",
            title: res.msg,
            message: res.data,
            showClose: false,
          });
        }
      });
    },
    getdictNbr() {
      const dict = "sex";
      getdictNbr(dict).then((res) => {
        this.sexList = res.data;
      });
    },
    getSubjectList() {
      subject().then((res) => {
        for (let index = 0; index < res.data.length; index++) {
          if (res.data[index].subjectType == 1) {
            this.firstMajorList.push(res.data[index]);
          } else if (res.data[index].subjectType == 2) {
            this.otherMajorList.push(res.data[index]);
          }
        }
      });
    },
    getArtList() {
      artexam(this.studentList.province).then((res) => {
        this.artList = res.data;
      });
    },
    // 年份列表
    getSelectList() {
      yearly().then((res) => {
        this.yearlyList = res.data;
      });
    },
    // 省份列表
    getProvince() {
      province().then((res) => {
        //   console.log(res);
        this.options = res.data.reverse();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.perfect {
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  position: fixed; // 将元素的定位方式设置为固定定位，相对于浏览器窗口进行定位。
  left: 0;
  top: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .perfect-box {
    width: 620px;
    height: 700px;
    // overflow: hidden;
    overflow: auto;
    position: relative;
    width: 600px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #fed7e9 0%, #ffffff 25%);
    .centent {
      overflow: auto;
      position: relative;
      width: 600px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: linear-gradient(180deg, #fed7e9 0%, #ffffff 25%);
      .el-icon-close {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
        user-select: none;
      }
      .title {
        border-radius: 10px 10px 0 0;
        width: 100%;
        height: 50px;
        padding: 10px 0;
        // background: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 22px;
      }
      .form {
        border-radius: 0 0 10px 10px;
        width: 85%;
        // height: 400px;
        display: flex;
        flex-direction: column;
        .form-li {
          width: 100%;
          height: 50px;
          line-height: 50px;
          display: flex;
          align-items: center;
          // justify-content: center;
          .left {
            display: flex;
            width: 120px;
            user-select: none;
            div {
              color: red;
            }
            margin-left: 15px;
          }
          .main {
            .hide-number-buttons {
              ::v-deep input::-webkit-outer-spin-button,
              ::v-deep input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              ::v-deep input[type="number"] {
                -moz-appearance: textfield;
              }
            }
            //   width: 300px;
            height: 50px;
            // background: #ffffff;
            border-radius: 5px;
            user-select: none;
            // padding-left: 10px;
            ::v-deep .el-radio {
              .el-radio__input.is-checked .el-radio__inner {
                border-color: #cc1d7a;
                background: #cc1d7a;
              }
              .el-radio__input.is-checked + .el-radio__label {
                color: black;
              }
            }
            ::v-deep .el-checkbox-group {
              .el-checkbox__input.is-checked .el-checkbox__inner {
                border-color: #cc1d7a;
                background: #cc1d7a;
              }
              .el-checkbox__input.is-checked + .el-checkbox__label {
                color: black;
              }
            }
            .el-cascader {
              width: 250px;
              // height: 35px;

              ::v-deep .el-input {
                //   border: none;

                //   height: 35px;
                .el-input__icon {
                  line-height: 35px;
                }
                .el-input__inner {
                  border: none;
                  // height: 35px;
                  border: 1px solid #ccc;
                }
              }
            }
            .el-select {
              width: 250px;
              // height: 35px;
              // border: 1px solid #CC1D7A;
              ::v-deep .el-input {
                //   border: none;

                //   height: 35px;
                .el-input__icon {
                  line-height: 35px;
                }
                .el-input__inner {
                  border: none;
                  // height: 35px;
                  border: 1px solid #ccc;
                }
              }
            }
            .el-input {
              width: 250px;
              // height: 35px;
              ::v-deep .el-input__inner {
                border: none;
                //   height: 35px;
                border: 1px solid #ccc;
              }
            }
          }
          .right {
            //   width: 150px;
            height: 50px;
            line-height: 50px;
            text-align: left;
            margin-left: 5px;
            margin-right: 15px;
            font-size: 14px;
            color: #bbb8b8;
            user-select: none;
          }
          // background: red;
        }
      }
      .button {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        .el-button {
          width: 300px;
          background: #cc1d7a;
          color: #ffffff;
          border: #cc1d7a;
        }
      }
    }
  }
}
</style>