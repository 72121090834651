<template>
  <div class="arithmetic">
    <div class="title">院校排名</div>
    <div class="ranking">
      <div class="ranking_title">
        <span>排名</span>
        <span>院校信息</span>
        <span>评分</span>
      </div>
      <div class="ranking_card">
        <div class="ranking_card_rank">1</div>
        <div class="ranking_card_img"></div>
        <div class="ranking_card_text">
          <div class="ranking_card_text_title">清华大学</div>
          <div class="ranking_card_text_tag">综合类</div>
        </div>
        <div class="ranking_card_points">1010</div>
        <div class="ranking_card_button">12</div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstitutionRanking",
  data() {
    return {

    }
  },
  methods: {
    arithmeticForm() {
      console.log("fto--", this.form)
    },
  }
}
</script>

<style lang="scss" scoped>

.arithmetic {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;

  .title {
    font-family: Source Han Sans SC, Source Han Sans SC;
    //font-weight: bold;
    font-size: 22px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 30px;
  }

  .ranking {
    width: 100%;

    .ranking_title {
      display: flex;
      :nth-of-type(1) {
        flex: 3;
        //padding-left: 10px;

      }
      :nth-of-type(2) {
        flex: 16
      }
      :nth-of-type(3) {
        flex: 4
      }

    }

    .ranking_card {
      width: 100%;
      height: 150px;
      //background-color: red;
      display: flex;
      align-items: center;

      .ranking_card_rank {
        width: 55px;
        //background-color: yellow;
      }

      .ranking_card_img {
        width: 135px;
        height: 135px;
        //background-color: pink;
        border-radius: 50%;
        margin-left: 30px;
      }

      .ranking_card_text {
        width: 480px;
        height: 150px;
        //background-color: #2ccae1;
        margin-left: 30px;

        .ranking_card_text_title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          font-size: 32px;
          color: #242424;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-top: 30px;
        }

        .ranking_card_text_tag {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 22px;
          color: #AEAEAE;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-top: 20px;
        }

      }

      .ranking_card_points {
        margin: 0 30px;
      }

      .ranking_card_button {
        margin-left: 30px;
      }

    }


  }


}

</style>
