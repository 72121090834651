<template>
  <div class="content">
    <div class="content-title">
      您已答完{{ JSON.parse(this.$route.query.data).length }}题
    </div>
    <div class="cards-list">
      <div
        v-for="(group, index) in groupScores"
        :key="index"
        class="card"
        :style="{
          backgroundColor:
            index == 0
              ? '#e2e3ff'
              : index == 1
              ? '#dbefff'
              : index == 2
              ? '#CAF6D7 '
              : index == 3
              ? '#FBECCD '
              : '',
        }"
      >
        <div
          class="card-title"
          :style="{
            color:
              index == 0
                ? '#00056A'
                : index == 1
                ? '#0B73DB'
                : index == 2
                ? '#009148 '
                : index == 3
                ? '#E69D07 '
                : '',
          }"
        >
          {{
            index == 0
              ? "内向（I）外向（E）"
              : index == 1
              ? "直觉（N）感觉（S）"
              : index == 2
              ? "思考（T）情感（F）"
              : index == 3
              ? "判断（J） 感知（P）"
              : ""
          }}
        </div>
        <div class="card-info">
          <div>您的{{ group.dimensionOne.dimension }}维度得分</div>
          <div>{{ group.dimensionOne.score }}分</div>
        </div>
        <div class="card-info">
          <div>您的{{ group.dimensionTwo.dimension }}维度得分</div>
          <div>{{ group.dimensionTwo.score }}分</div>
        </div>
      </div>
    </div>
    <div class="content-title1">您的专属MBTI测试报告已生成</div>
    <div class="info-card">
      <div class="card-detail">
        <div class="detail-left">
          <div class="left-title">您的测试类型</div>
          <div class="left-type">
            {{ answerInfo.type }} <span>{{ answerInfo.name }}</span>
          </div>
          <div class="left-desc">{{ answerInfo.xxms }}</div>
        </div>
        <div class="detail-right">
          <div
            class="right-list"
            v-for="(score, index) in groupScores"
            :key="index"
          >
            <div class="list-left">
              <div class="info">
                {{
                  score.dimensionOne.dimension == "E"
                    ? "外向（E）"
                    : score.dimensionOne.dimension == "S"
                    ? "感觉（S)"
                    : score.dimensionOne.dimension == "T"
                    ? "思考（T)"
                    : score.dimensionOne.dimension == "J"
                    ? "判断（J）"
                    : ""
                }}
              </div>
              <div class="prossgress">
                <div
                  class="prossgress-bar"
                  v-if="score.dimensionOne.score > score.dimensionTwo.score"
                  :style="{
                    width: `${(score.dimensionOne.score / 57) * 100}%`,
                    backgroundColor:
                      index == 0
                        ? '#00056A'
                        : index == 1
                        ? '#0B73DB'
                        : index == 2
                        ? '#009148 '
                        : index == 3
                        ? '#E69D07 '
                        : '',
                  }"
                ></div>
                <div
                  class="prossgress-bar-info"
                  v-if="score.dimensionOne.score > score.dimensionTwo.score"
                  :style="{
                    position: 'absolute',
                    top: '-1px',
                    right: `calc(((${
                      score.dimensionOne.score / 57
                    }) * 100% - 30px)`,
                    backgroundColor:
                      index == 0
                        ? '#00056A'
                        : index == 1
                        ? '#0B73DB'
                        : index == 2
                        ? '#009148 '
                        : index == 3
                        ? '#E69D07 '
                        : '',
                  }"
                >
                  {{ score.dimensionOne.score }}
                </div>
              </div>
            </div>
            <div class="list-right">
              <div class="info">
                {{
                  score.dimensionTwo.dimension == "I"
                    ? "内向（I）"
                    : score.dimensionTwo.dimension == "N"
                    ? "直觉（N）"
                    : score.dimensionTwo.dimension == "F"
                    ? "情感（F）"
                    : score.dimensionTwo.dimension == "P"
                    ? "感知（P）"
                    : ""
                }}
              </div>
              <div class="prossgress">
                <div
                  class="prossgress-bar"
                  v-if="score.dimensionTwo.score > score.dimensionOne.score"
                  :style="{
                    width: `${(score.dimensionTwo.score / 57) * 100}%`,
                    backgroundColor:
                      index == 0
                        ? '#00056A'
                        : index == 1
                        ? '#0B73DB'
                        : index == 2
                        ? '#009148 '
                        : index == 3
                        ? '#E69D07 '
                        : '',
                  }"
                ></div>
                <div
                  class="prossgress-bar-info"
                  v-if="score.dimensionTwo.score > score.dimensionOne.score"
                  :style="{
                    position: 'absolute',
                    top: '-1px',
                    left: `calc(((${
                      score.dimensionTwo.score / 57
                    }) * 100% - 30px)`,
                    backgroundColor:
                      index == 0
                        ? '#00056A'
                        : index == 1
                        ? '#0B73DB'
                        : index == 2
                        ? '#009148 '
                        : index == 3
                        ? '#E69D07 '
                        : '',
                  }"
                >
                  {{ score.dimensionTwo.score }}
                </div>
              </div>
            </div>
          </div>
          <!--   <div class="right-list">
            <div class="list-left">
              <div class="info">内向（I）</div>
              <div class="prossgress">
                <div class="prossgress-bar" :style="{ width:(`${groupScores[0].dimensionTwo.score}`/57)*100+'%' }"></div>
                <div class="prossgress-bar-info" :style="{ position: 'absolute', top: '-1px', right: `calc(((${groupScores[0].dimensionTwo.score/57})*100% - 30px) `, float: 'right'}">{{ groupScores[0].dimensionTwo.score }}</div>
              </div>
            </div>
            <div class="list-right">
              <div class="prossgress">
                <div class="prossgress-bar"></div>
                <div class="prossgress-bar-info"></div>
              </div>
              <div class="info">外向（E）</div>
            </div>
          </div>
          <div class="right-list">
            <div class="list-left">
              <div class="info">直觉（N）</div>
              <div class="prossgress">
                <div class="prossgress-bar"></div>
                <div class="prossgress-bar-info"></div>
              </div>
            </div>
            <div class="list-right">
              <div class="prossgress">
                <div class="prossgress-bar"></div>
                <div class="prossgress-bar-info"></div>
              </div>
              <div class="info">感觉（S）</div>
            </div>
          </div>
          <div class="right-list">
            <div class="list-left">
              <div class="info">思考（T）</div>
              <div class="prossgress">
                <div class="prossgress-bar"></div>
                <div class="prossgress-bar-info"></div>
              </div>
            </div>
            <div class="list-right">
              <div class="prossgress">
                <div class="prossgress-bar"></div>
                <div class="prossgress-bar-info"></div>
              </div>
              <div class="info">情感（F）</div>
            </div>
          </div>
          <div class="right-list">
            <div class="list-left">
              <div class="info">判断（J）</div>
              <div class="prossgress">
                <div class="prossgress-bar" :style="{ width:` (groupScores[3].dimensionOne.score/57)*100+'%'` }"></div>
                <div class="prossgress-bar-info" :style="{ position: 'absolute', top: '-1px', right: `calc(((${groupScores[3].dimensionOne.score/57})*100% - 30px) `, float: 'right'}">{{ groupScores[3].dimensionOne.score }}</div>
              </div>
            </div>
            <div class="list-right">
              <div class="prossgress">
                <div class="prossgress-bar" :style="{ width: `(${groupScores[3].dimensionTwo.score/57})*100+'%'` }"></div>
                <div class="prossgress-bar-info">23</div>
              </div>
              <div class="info">感知（P）</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="card_msg">
      <div class="title"><div>性格整体特征</div></div>
      <div class="msg-list">{{ answerInfo.xgtz }}
      </div>
    </div>
    <div class="card_msg">
      <div class="title"><div>职业倾向</div></div>
      <div class="msg-list">{{ answerInfo.zyqx}}
      </div>
    </div>
    <div class="card_msg">
      <div class="title"><div>性格盲点</div></div>
      <div class="msg-list">{{ answerInfo.xgmd}}
      </div>
    </div>
    <div class="card_msg">
      <div class="title"><div>解决意见</div></div>
      <div class="msg-list">{{ answerInfo.jjyj}}
      </div>
    </div>
    <div class="card_msg">
      <div class="title"><div>名人</div></div>
      <div class="msg-list">{{ answerInfo.mr}}
      </div>
    </div>
    <div class="card_msg">
      <div class="title"><div>学习模式</div></div>
      <div class="msg-list">{{ answerInfo.xxms}}
      </div>
    </div>
    <div class="card_msg">
      <div class="title"><div>解决问题模式</div></div>
      <div class="msg-list">{{ answerInfo.jjwtms}}
      </div>
    </div><div class="card_msg">
      <div class="title"><div>环境倾向性</div></div>
      <div class="msg-list">{{ answerInfo.hjqxx}}
      </div>
    </div>
  </div>
</template>

<script>
import { queryAnswer } from "@/api/index";

export default {
  name: "answer",
  data() {
    return {
      answerInfo: {},
      groupScores: [],
    };
  },
  computed: {
    computedStyle() {},
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let data = JSON.parse(this.$route.query.data);
      console.table(data);
      queryAnswer(data).then((res) => {
        this.answerInfo = res.data.answerInfo;
        this.groupScores = res.data.groupScores;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 1280px;
  height: 100%;
  background: #fff;
  margin: 30px auto;
  overflow: hidden;
  .content-title {
    font-size: 32px;
    color: #333;
    text-align: center;
    margin: 30px;
  }
  .cards-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 130px;
    .card {
      width: 210px;
      height: 130px;
      border-radius: 10px 10px 10px 10px;
      .card-title {
        font-size: 18px;
        margin-top: 20px;
        padding-left: 16px;
        border-left: 5px solid #fff;
        font-weight: bold;
      }
      .card-info {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 15px;
      }
    }
  }
  .content-title1 {
    margin: 30px auto;
    text-align: center;
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-weight: 400;
    font-size: 32px;
    color: #373737;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .info-card {
    margin: 0 auto;
    width: 886px;
    // height: 230px;
    border: 1px solid #a5a4a4;
    border-radius: 10px 10px 10px 10px;
    .card-detail {
      width: 826px;
      //   height: 170px;
      margin: 30px auto;
      display: flex;
      .detail-left {
        width: 227px;
        .left-title {
          font-size: 22px;
        }
        .left-type {
          width: 227px;
          height: 53px;
          font-size: 38px;
          color: #cc1d7a;
          text-align: center;
          background: #ffe6f3;
          border-radius: 9px 9px 9px 9px;
          line-height: 53px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 20px auto;
          span {
            font-weight: 400;
            font-size: 22px;
            line-height: 22px;
            color: #cc1d7a;
          }
        }
      }
      .detail-right {
        width: 569px;
        margin-left: 55px;
        .right-list {
          display: flex;
          .list-left {
            width: 50%;
            display: flex;
            .info {
              width: 80px;
            }
            .prossgress {
              position: relative;
              width: 195px;
              height: 16px;
              background: #f5f5f5;
              border-radius: 50px 50px 50px 50px;
              .prossgress-bar {
                float: right;
                width: 0%;
                height: 16px;
                // background: #cc1d7a;
                border-radius: 50px 50px 50px 50px;
              }
              .prossgress-bar-info {
                position: absolute;
                top: -1px;
                right: calc(30% - 30px);
                float: right;
                width: 30px;
                color: #fff;
                float: right;
                height: 16px;
                line-height: 16px;
                font-size: 12px;
                background: #cc1d7a;
                border-radius: 50px 50px 50px 50px;
                border: 2px solid #fff;
                text-align: center;
              }
            }
          }
          .list-right {
            width: 50%;
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: 30px;
            .info {
              width: 80px;
              padding-left: 10px;
            }
            .prossgress {
              width: 195px;
              height: 16px;
              background: #f5f5f5;
              border-radius: 50px 50px 50px 50px;
              position: relative;
              .prossgress-bar {
                width: 0%;
                height: 16px;
                background: #cc1d7a;
                border-radius: 50px 50px 50px 50px;
              }
              .prossgress-bar-info {
                position: absolute;
                top: -1px;
                left: calc(40% - 30px);
                width: 30px;
                color: #fff;
                height: 16px;
                line-height: 16px;
                font-size: 12px;
                background: #cc1d7a;
                border-radius: 50px 50px 50px 50px;
                border: 2px solid #fff;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  .card_msg {
    width: 1200px;
    margin: 30px auto;
    overflow: hidden;
    .title {
      width: 178px;
      height: 48px;
      // padding-left: 20px;
      line-height: 48px;
      background: #cc1d7a;
      border-radius: 0px 20px 0px 0px;
      color: #fff;
      font-size: 20px;
      overflow: hidden;
      div{
        padding-left: 20px;
        height: 30px;
        line-height: 30px;
        margin-top: 9px;
        border-left: #fff solid 7px;
      }
    }
    .msg-list{
      width: 1150px;
      border: 1px solid #A5A4A4 ;
      padding: 20px;
      border-radius: 0px 10px 10px 10px;
      line-height: 2;
    }
  }
}
</style>