<template>
  <div class="enrolldetail" v-html="html">

  </div>
</template>

<script>
import {enrollmentDetail, informationDetail} from "@/api";
import cache from "@/utils/cache";

export default {
  name: "Enrolldetail",
  data() {
    return {
      html: ""
    }
  },
  methods: {
    getEnrollmentDetail(id) {
      enrollmentDetail(id).then(res => {
        if (res.code === 200) {
          this.html = res.data.info;
        }
      })
    },
    getInformationDetail(id) {
      informationDetail(id).then(res => {
        if (res.code === 200) {
          this.html = res.data.info;
        }
      })
    },
    initHtml() {
      this.html = cache.local.get('information')
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    let type = urlParams.get('type');
    const recordId = urlParams.get('id')
    let params = {recordId}
    if ((type == null || type === '') && (recordId == null || recordId === '')) {
      this.initHtml()
      return
    }
    if (type === "dynamic") {
      this.getEnrollmentDetail(params);
    } else {
      this.getInformationDetail(params);
    }
  },
}
</script>

<style scoped lang="scss">
.enrolldetail {
  margin: 30px auto;
  width: 1280px;
  background-color: white;
  padding: 20px;
}
</style>
