<template>
  <div class="container">
    <div class="left">
      <div class="left-title"></div>
      <div
        class="left-content"
        @click="activeto(1)"
        :class="act == 1 ? 'active1' : ''"
      >
        <div :class="act == 1 ? 'active' : ''">算投档</div>
      </div>
      <div
        class="left-content"
        @click="activeto(2)"
        :class="act == 2 ? 'active1' : ''"
      >
        <div :class="act == 2 ? 'active' : ''">查位次</div>
      </div>
        <div
        class="left-content"
        @click="activeto(3)"
        :class="act == 3 ? 'active1' : ''"
      >
        <div :class="act == 3 ? 'active' : ''">录取查询</div>
      </div>
      <div
        class="left-content"
        @click="activeto(4)"
        :class="act == 4 ? 'active1' : ''"
      >
        <div :class="act == 4 ? 'active' : ''">校考日历</div>
      </div>
      <div
        class="left-content"
        @click="activeto(5)"
        :class="act == 5 ? 'active1' : ''"
      >
        <div :class="act == 5 ? 'active' : ''">院校排名</div>
      </div>
      <div
        class="left-content"
        @click="activeto(6)"
        :class="act == 6 ? 'active1' : ''"
      >
        <div :class="act == 6 ? 'active' : ''">教你填</div>
      </div>
    </div>
    <div class="right">
      <div v-if="act == 1" class="right-content one">
        <ArithmeticFile/>
      </div>
      <div v-if="act == 2" class="right-content two">
        <RankLookup/>
      </div>
      <div v-if="act == 3" class="right-content there">
        <!-- <AdmissionInquiry/> -->
      </div>
      <div v-if="act == 4" class="right-content four">
        <!-- <SchoolCalendar/> -->
      </div>
      <div v-if="act == 5" class="right-content five">
        <!-- <InstitutionRanking/> -->
      </div>
      <div v-if="act == 6" class="right-content six">
        <!-- <TeachYouToFillOut/> -->
      </div>
    </div>
  </div>
</template>
<script>
import Undefine from "@/components/undefine/index.vue";
import ArithmeticFile from "./components/ArithmeticFile.vue";
import RankLookup from "./components/RankLookup.vue";
import AdmissionInquiry from "./components/AdmissionInquiry.vue";
import SchoolCalendar from "./components/SchoolCalendar.vue";
import InstitutionRanking from "./components/InstitutionRanking.vue";
import TeachYouToFillOut from "./components/TeachYouToFillOut.vue";

export default {
  name: "SimplifyTools",
  components: {
    Undefine,
    ArithmeticFile,
    RankLookup,
    AdmissionInquiry,
    SchoolCalendar,
    InstitutionRanking,
    TeachYouToFillOut
  },
  data() {
    return {
      act: 1,
    };
  },
  mounted() {
    this.getActOfQuery();
  },
  methods: {
    getActOfQuery() {
      this.act = this.$route.query.id || 1;
    },
    activeto(data) {
      this.act = data;
    },
  },
};
</script>
<style scoped lang="scss">
//引入公共样式表
@import "@/assets/style/businessTheme.scss";

.container {
  width: 1280px;
  // height: 1000px;
  // background: #ccc;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  .left {
    width: 265px;
    height: 515px;
    background: #fff;
    .active1 {
      background: linear-gradient(270deg, #ffffff 0%, #ffd5c8 100%);
    }
    .left-title {
      width: 100%;
      height: 77px;
      border-bottom: 2px #f5f5f5 solid;
      user-select: none;
    }
    .left-content {
      width: 100%;
      height: 30px;
      border-bottom: 1px #f5f5f5 solid;
      padding: 21px 0;
      div {
        padding-left: 58px;
        font-size: 22px;
        //font-weight: bold;
        user-select: none;
        line-height: 30px;
        border-left: #fff 4px solid;
      }
      .active {
        height: 30px;
        border-left: #ef5a2b 4px solid;
      }
    }
  }
  .right {
    width: 985px;
    min-height: 500px;
    background: #fff;
    .right-content {
      width: 100%;
      //height: 500px;
    }
    .one {
      height: 500px;
    }
    //.two {
    //  height: 500px;
    //  width: 925px;
    //  //margin:0 auto;
    //  .select{
    //    margin-top: 30px;
    //    font-size: 20px;
    //    .title{
    //      width: 100%;
    //      height: 50px;
    //      //font-weight: bolder;
    //    }
    //    .form{
    //
    //    }
    //  }
    //  .table {
    //    width: 925px;
    //    .th {
    //      display: flex;
    //      width: 925px;
    //    }
    //  }
    //}
  }
}
</style>