<template>
  <div class="main-nav-box">
    <div class="main-nav-inner-box">
      <ul class="left-nav-box">
        <li
          v-for="(item, index) in dynamicRouter()"
          :key="item.name"
          style="font-weight: 700"
          @click="pageJump(item)"
          :class="
            activeMenuName == item.name
              ? ['menu-box', 'active-menu-box', 'menu-box-' + index]
              : ['menu-box', 'menu-box-' + index]
          "
        >
          {{ item.meta.title }}
        </li>
      </ul>
      <div class="right-button-box">
        <el-dialog title="请绑定会员卡" :visible.sync="dialogTableVisible">
        </el-dialog>
        <el-dialog class="xinxi" :visible.sync="peple"></el-dialog>
        <div v-if="this.MocScore!=0">综合分:{{ this.vip==1?this.MocScore:'***' }}</div>
        <div
          class="login"
          @mouseenter="showLogout(1)"
          @mouseleave="showLogout(2)"
          @click.prevent="!isLoggedIn && login()"
        >
          {{ this.username }}
          <transition name="fade">
            <div
              v-if="isLoggedIn && showLogout1"
              class="logout-option"
              @click.stop
            >
              <transition-group name="list">
                <div
                  class="logout-option-item"
                  key="personal"
                  @click.stop="goToPersonalCenter"
                  @mouseenter="highlightItem($event)"
                  @mouseleave="unhighlightItem($event)"
                >
                  个人中心
                </div>
                <!-- <div
                  class="logout-option-item"
                  key="password"
                  @click.stop="goToChangePassword"
                  @mouseenter="highlightItem($event)"
                  @mouseleave="unhighlightItem($event)"
                >
                  修改密码
                </div> -->
                <div
                  class="logout-option-item"
                  key="logout"
                  @click.stop="logout"
                  @mouseenter="highlightItem($event)"
                  @mouseleave="unhighlightItem($event)"
                >
                  退出登录
                </div>
              </transition-group>
            </div>
          </transition>
        </div>

        <Modal v-if="showModal1"> </Modal>
        <img
          src="@/assets/images/topSearch/login.png"
          style="margin-left: 25px"
        />
        <div
          class="province-selector"
          style="position: relative"
          @mouseleave="hideProvinceList"
        >
          <div
            style="
              width: 70px;
              text-align: center;
              cursor: pointer;
              border: 1px solid #ffffff;
              padding: 5px 10px;
              border-radius: 4px;
              display: flex;
              justify-content: space-between;
              align-items: baseline;
            "
            @mouseenter="showProvinceList()"
          >
            {{ regionName.name || "请选择" }}
            <i class="el-icon-arrow-down"></i>
          </div>
          <transition name="fade">
            <div
              @mouseleave="hideProvinceList"
              v-if="showId"
              style="
                border-radius: 10px;
                width: 450px;
                position: absolute;
                top: 100%;
                left: -150px;
                background: #ffffff;
                z-index: 999;
                color: #000000;
                display: flex;
                flex-flow: column wrap;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: row;
                backdrop-filter: blur(5px);
                padding: 10px;
              "
            >
              <div
                v-for="province in provinceList"
                :key="province.id"
                style="
                  cursor: pointer;
                  width: 75px;
                  height: 50px;
                  line-height: 50px;
                  text-align: center;
                  /* border: 1px solid #ffffff; */
                  border-radius: 4px;
                  margin: 6px;
                "
                @click="selectProvince(province)"
                @mouseenter="highlightItem($event)"
                @mouseleave="unhighlightItem($event)"
              >
                {{ province.abbrName }}
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dynamicRouter from "@/router/dynamic-router";
import Modal from "./Login/index.vue";

import { province, userProvince, logout, queryById1 } from "@/api/index.js";
import { EventBus } from "@/utils/eventBus";


export default {
  name: "MainNav",
  components: {
    Modal,
  },
  computed: {
    activeMenuName() {
      return this.$route.name;
    },
    userInfo() {
      return localStorage.getItem("username");
    },
  },


  data() {
    return {
      userList: {},
      userLogin: {
        user_phone: "",
      },
      cardLogin: {
        user_phone: "",
      },
      userid: "",
      showModal: false,
      showModal1: false,
      provinceList: [],
      value: "",
      dialogTableVisible: false,
      peple: false,
      isLoggedIn: false,
      showLogout1: false,
      regionName: {
        id: "",
        name: "",
      },
      showId: false,
      username: localStorage.getItem("username") || "登录",
      MocScore: 0,
      vip:0,
    };
  },
  mounted() {
    this.getUserList();
    this.getProvince();
    this.getCurrentProvince();
    this.getUserProvince();
    EventBus.$on("getProvince", this.getCurrentProvince);
  },
  watch: {
    "$store.state.noopsychefill.province": {
      handler(newVal, oldVal) {
        console.log("province", newVal, oldVal);
        this.regionName.id = newVal.id;
        this.regionName.name = newVal.name;
        const selectedProvince = this.provinceList.find(
          (item) => item.recordId === newVal.id
        );
        if (selectedProvince) {
          this.regionName.name = selectedProvince.abbrName;
          localStorage.setItem("province", JSON.stringify(this.regionName));
        }
      },
      deep: true,
    },
    "$store.state.home.userName": {
      handler(newVal, oldVal) {
        this.username = newVal;
        localStorage.setItem("username", newVal);
        this.get()
      },
      deep: true,
    },
    "$store.state.home.loginState": {
      handler(newVal, oldVal) {
        console.log("loginState", newVal, oldVal);
        if (newVal.state == true) {
          this.$store.commit("setUserList", "登录");
        }
        this.showModal1 = newVal.state;
        this.isLoggedIn = newVal.islogin;
       
      },
      deep: true,
    },
    "$store.state.home.isVip": {
      handler(newVal, oldVal) {       
        this.vip = newVal;
      },
      deep: true,
    },
    "$store.state.home.comScore": {
      handler(newVal, oldVal) {
       
        this.get();
      },
      deep: true,
    },
  },

  methods: {
    get() {
      let id = JSON.parse(localStorage.getItem("userList"))?.recordId;
      queryById1(id).then(
        res => {
          console.log(',',res.data);
          this.MocScore = res.data.comScore.toFixed(2);
        }
      )
     /*  if (localStorage.getItem("studentDetail")) {
        let mocScores = JSON.parse(
        localStorage.getItem("studentDetail")
      ).comScore;
        console.log(mocScores, "mocScores");
      this.MocScore = mocScores.toFixed(2);
      } */

      
    },
    login() {
      this.showModal1 = true;
      this.isLoggedIn = true;
      this.$store.commit("setLoginState", { state: true, islogin: false });
    },
    // 从本地获取省份
    getCurrentProvince() {
      if (localStorage.getItem("province")) {
        this.regionName = JSON.parse(localStorage.getItem("province"));
      }
    },
    // 选择省份
    selectProvince(province) {
      // console.log("province", province);
      this.regionName.name = province.abbrName;
      this.regionName.id = province.recordId;
      localStorage.setItem("province", JSON.stringify(this.regionName));
      this.showId = false;
      this.$store.commit("province", {
        id: province.recordId,
        name: province.abbrName,
      });
      window.location.reload();
    },
    // 显示省份列表
    showProvinceList() {
      if (this.isLoggedIn == false || this.isLoggedIn == null) {
        this.showId = true;
      } else {
        this.showId = false;
      }
    },
    // 隐藏省份列表
    hideProvinceList() {
      this.showId = false;
    },
    // 获取用户ip
    getUserProvince() {
      // console.log(5555555);
      if (localStorage.getItem("province")) {
        return;
      } else {
        userProvince().then((res) => {
          // console.log(123, res);
          if (res.data) {
            this.$store.commit("province", {
              id: res.data.recordId,
              name: res.data.abbrName,
            });
            this.regionName.id = res.data.recordId;
            this.regionName.name = res.data.abbrName;
          } else {
            this.regionName.id = "10300000000007";
            this.regionName.name = "辽宁";
            this.$store.commit("province", {
              id: "10300000000007",
              name: "辽宁",
            });
          }
        });
      }
    },
    // 获取省份列表
    getProvince() {
      province().then((res) => {
        this.provinceList = res.data.reverse();
        let province = this.userList.province;
        // console.log(this.provinceList);
      });
    },
    // 高亮效果
    highlightItem(event) {
      event.target.style.backgroundColor = "#fff4f2";
      event.target.style.color = "#000000";
      event.target.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.1)";
      event.target.style.transform = "translateY(-2px)";
      event.target.style.transition = "all 0.3s ease";
      event.target.style.borderRadius = "4px";
    },
    // 取消高亮效果
    unhighlightItem(event) {
      event.target.style.backgroundColor = "#ffffff";
      event.target.style.color = "#000000";
      event.target.style.boxShadow = "none";
      event.target.style.transform = "translateY(0)";
      event.target.style.borderRadius = "4px"; // 添加圆角
      event.target.style.transition = "all 0.3s ease"; // 保持过渡效果
    },
    // 个人中心
    goToPersonalCenter() {
      // event.target.style.cursor = "pointer";
      this.$router.push({ name: "personalCenter" });
    },
    // 修改密码
    goToChangePassword() {
      this.$message({
        message: "修改密码功能正在开发中，敬请期待！",
        type: "info",
        duration: 3000,
      });
      // this.$router.push({ name: 'changePassword' });
    },
    // 显示退出登录
    logout() {
      this.showLogout1 = false;
      this.getUserList();
      logout().then((res) => {
        if (res.code == 200) {
          localStorage.clear();
          localStorage.removeItem("userLogin");
          localStorage.removeItem("cardLogin");
          // localStorage.removeItem("province");
          localStorage.removeItem("username");
          localStorage.removeItem("recordId");
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          this.MocScore = 0
          // this.getUserProvince();
          this.$store.commit("setUserList", "登录");
          this.username = "登录";
        }
      });
      // 重置登录状态
      this.showModal = false;
      this.userLogin = null;
      this.cardLogin = null;
      this.isLoggedIn = false;
      this.username = "登录";
      // 提示用户已退出登录
      setTimeout(() => {
        this.$router.push({ name: "home" });
      }, 1000);
      // 可选：重定向到首页或登录页
    },
    // 退出登录
    showLogout(num) {
      if (num == 1) {
        this.showLogout1 = true;
      } else {
        this.showLogout1 = false;
      }
    },
    // 隐藏中间四位数字
    hideMiddleFour(str) {
      return str.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
    },
    // 获取用户列表
    getUserList() {
      this.userLogin = JSON.parse(localStorage.getItem("userLogin"));
      this.cardLogin = JSON.parse(localStorage.getItem("cardLogin"));
      this.isLoggedIn = this.userLogin || this.cardLogin;
    },
    // 动态路由
    dynamicRouter() {
      return dynamicRouter;
    },
    // 关闭回调
    success(res) {
      this.showModal = res;
      this.$store.commit("setLoginState", true);
      this.getUserList();
    },
    // 页面跳转
    pageJump(route) {
      this.$router.push({ name: route.name });
    },
  },
};
</script>

<style scoped lang="scss">
//引入公共样式表
@import "@/assets/style/businessTheme.scss";

.main-nav-box {
  width: 100%;
  height: 50px;
  background-color: $page-main-color;
  box-sizing: border-box;
  padding-left: $page-content-padding-left;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  .main-nav-inner-box {
    width: $page-content-width;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-nav-box {
      // width: 900px;
      // width: 100%;

      height: 100%;
      display: flex;
      justify-content: space-evenly;

      .menu-box {
        width: 130px;
        text-align: center;
        height: 100%;
        box-sizing: border-box;
        padding: 12px;
        cursor: pointer;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
      }
      .menu-box:hover {
        background-color: #ffffff;
        color: $page-main-color;
      }
      .active-menu-box {
        color: $page-main-color;
        background-color: #ffffff;
      }
    }
    .right-button-box {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      // width: 180px;
      color: #ffffff;
      position: relative;
      .provinceBox {
        cursor: pointer;
        width: 150px;
        height: 30px;
        line-height: 30px;
        /* text-align: center; */
        background: #cc1d7a;
        color: #ffffff;
        /* margin-left: 10px; */
        border: 1px solid #ffffff;
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
        padding: 0 10px;
      }
      .province-box {
        width: 500px;
        height: 300px;
        // line-height: 30px;
        // text-align: center;
        background: #f6f6f6;
        color: #000000;
        border: 1px solid #ffffff;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 999;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        // overflow: auto;
      }
      .login {
        margin-left: 10px;
        // width: 50px;
        padding-right: 30px;
        height: 30px;
        line-height: 30px;
        // text-align: center;
      }
      .logout-option {
        width: 150px;
        // height: 100px;
        background-color: #ffffff;
        z-index: 999;
        color: #000000;
        position: absolute;
        // top: 100%;
        top: 30px;
        // right: -100px;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        // padding: 5px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        .logout-option-item {
          width: 150px;
          height: 50px;
          line-height: 50px;
          cursor: pointer;
          text-align: center;
        }
      }
      .xinxi {
        height: 1142px;
        width: 1000px;
        background: black;
      }
      .custom-select {
        width: 100px;
      }
      .custom-select ::v-deep .el-input {
        font-size: 14px;
        .el-input__suffix {
          height: 100%;
          .el-input__icon {
            line-height: 30px;
          }
        }
        .el-input__inner {
          border: 1px solid #fff;
          background-color: #cc1d7a;
          color: #ffffff;
          height: 30px;
          line-height: 30px;
        }
        .el-select__caret {
          color: #fff;
        }
        .el-input__inner::-webkit-input-placeholder {
          color: #fff;
        }
      }
    }
  }
}
</style>