<template>
  <div class="content">
    <div class="main">
      <div class="main-content">
        <div class="main-content-title">
          <h1>MBTI性格测试</h1>
          <div class="main-content-title-text">
            你是<span>i</span>人还是<span>e</span>人，坚持一下完成探索
          </div>
        </div>
        <div class="main-content-cardlist">
          <div
            class="main-content-card"
            v-for="(item, index) in cardList"
            :key="item.id"
          >
            <img :src="item.imgs" alt="" />
            <div class="main-content-card-text">{{ item.text }}</div>
          </div>
        </div>
        <div class="main-content-question">
          <div class="main-content-question-progress">
            <div class="main-content-question-progress-text">
              {{ questionIndex }}/{{ questionList.length }}
            </div>
            <div class="main-content-question-progress-bar"></div>
            <div
              class="main-content-question-progress-bar1"
              :style="{
                width: `${(900 / questionList.length) * questionIndex}px`,
              }"
            ></div>
          </div>
          <div class="main-content-question-main">
            <div class="main-content-question-main-title">
              {{ questionIndex }}.{{ showList.question }}
            </div>

            <div
              :class="
                item.optionValue === answerValue
                  ? 'main-content-question-main-selected'
                  : 'main-content-question-main-select'
              "
              @click="selected(item, showList)"
              v-for="item in showList.options"
              :key="item.optionId"
            >
              {{ item.optionName }}
            </div>
          </div>
        </div>
        <div class="main-content-buttons">
          <el-button
            class="main-content-buttons-last"
            :disabled="questionIndex === 1 ? true : false"
            @click="lastQuestion()"
          >
            上一题
          </el-button>
          <el-button
            v-if="questionIndex != questionList.length"
            :disabled="questionIndex === questionList.length ? true : false"
            class="main-content-buttons-next"
            @click="nextQuestion()"
          >
            下一题
          </el-button>
          <el-button
            class="main-content-buttons-next"
            v-if="questionIndex == questionList.length"
            @click="submit()"
            >提交</el-button
          >
        </div>
        <div class="main-content-footer">
          <div class="main-content-footer-lab">完成测试后，您将获得</div>
          <div class="main-content-footer-list">
            <div class="main-content-footer-list-li">
              ·获取您的字母类型测试结果
            </div>
            <div class="main-content-footer-list-li">
              ·发现适合您性格类型的职业
            </div>
          </div>
          <div class="main-content-footer-list">
            <div class="main-content-footer-list-li">
              ·知悉您的偏好优势和类型描述
            </div>
            <div class="main-content-footer-list-li">
              ·了解您的沟通风格和学习风格
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryQuestion, queryAnswer } from "@/api/index";

export default {
  name: "Mbti",
  data() {
    return {
      // 卡片列表
      cardList: [
        {
          id: 1,
          text: "测试没有具体时间限制，通常 在10分钟内完成",
          imgs: require("@/assets/images/Mbti/1.jpg"),
        },
        {
          id: 2,
          text: "按实际行为和感受选择，而不 是想要和期望的",
          imgs: require("@/assets/images/Mbti/2.jpg"),
        },
        {
          id: 3,
          text: "尽量避免重做上一题，相信你的 第一选择就好",
          imgs: require("@/assets/images/Mbti/3.jpg"),
        },
      ],
      //   问题列表
      questionList: [],
      //   显示的题目
      showList: [],
      //   答案列表
      answerList: [],
      //   索引Id
      questionIndex: 0,
      //   答案值
      answerValue: "",
    };
  },
  mounted() {
    this.getQuestion();
  },
  methods: {
    // 提交答案
    submit() {
      let data = [];
      if (this.answerValue == "") {
        this.$notify.error({
          title: "错误",
          message: "请选择答案",
        });
      } else {
        this.answerList.forEach((item) => {
          this.questionList.forEach((Index_1) => {
            if (Index_1.recordId == item.recordId) {
              data.push({
                groupId: Index_1.groupId,
                optionValue: item.optionValue,
              });
            }
          });
        });
       /* console.log(data);
         this.$notify.success({
          title: "成功",
          message: "页面暂未开放！",
        }); */
        this.$router.push({
          name: "mbtiAnswer",
          query: {
            data: JSON.stringify(data),
          },
        });
      }
    },
    // 上一题
    lastQuestion() {
      this.questionIndex--;
      this.showList = this.questionList[this.questionIndex - 1];
      this.answerValue = "";
      this.answer();
    },
    // 下一题
    nextQuestion() {
      if (this.answerValue == "") {
        this.$notify.error({
          title: "错误",
          message: "请选择答案",
        });
      } else {
        this.questionIndex++;
        this.showList = this.questionList[this.questionIndex - 1];
        this.answerValue = "";
        this.answer();
      }
    },
    // 答案返显
    answer() {
      this.answerList.forEach((Index_1) => {
        if (Index_1.recordId == this.showList.recordId) {
          this.answerValue = Index_1.optionValue;
        }
      });
    },
    // 选择答案
    selected(item, list) {
      // console.log(item, list);
      this.answerList.forEach((Index_1) => {
        if (Index_1.recordId == list.recordId) {
          Index_1.optionValue = item.optionValue;
          this.answerValue = item.optionValue;
        }
      });
    },
    // 获取题目
    getQuestion() {
      queryQuestion().then((res) => {
        if (res.code == 200) {
          // 保存问题列表
          this.questionList = res.data;
          this.answerList = res.data.map((item) => {
            return { recordId: item.recordId, optionValue: "" };
          });
          // 显示第一题
          this.showList = JSON.parse(JSON.stringify(res.data[0]));
          this.questionIndex = 1;
        } else {
          this.$notify.error({
            title: "错误",
            message: res.msg,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: calc(100% - 0px);
  min-height: calc(100vh - 380px);
  background: url(@/assets/images/Mbti/background.jpg) no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  .main {
    width: 1280px;
    height: 1000px;
    // background: #fff;
    margin: 30px auto;
    .main-content {
      width: 1000px;
      margin: 0 auto;
      .main-content-title {
        text-align: center;
        h1 {
          font-weight: 400;
          font-family: Source Han Sans SC, Source Han Sans SC;
          color: #cc1d7a;
          margin-bottom: 10px;
        }
        .main-content-title-text {
          font-size: 20px;
          span {
            color: #cc1d7a;
          }
        }
      }
      .main-content-cardlist {
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .main-content-card {
          width: 282px;
          height: 172px;
          background: #fffcfc;
          border-radius: 10px 10px 10px 10px;
          position: relative;
          img {
            position: absolute;
            left: 50%;
            top: 20px;
            transform: translateX(-50%);
          }
          .main-content-card-text {
            position: absolute;
            left: 50%;
            top: 106px;
            transform: translateX(-50%);
            width: 200px;
            text-align: center;
          }
        }
      }
      .main-content-question {
        margin-top: 50px;
        width: 1000px;
        min-height: 320px;
        border-radius: 20px 20px 20px 20px;
        background: #b2b6ff;
        .main-content-question-progress {
          width: 960px;
          height: 50px;
          margin: 0 auto;
          text-align: center;
          position: relative;
          display: flex;
          .main-content-question-progress-text {
            position: absolute;
            top: 15px;
            margin-right: 10px;
            // width: 50px;
          }
          .main-content-question-progress-bar {
            position: absolute;
            top: 15px;
            left: 60px;
            width: 900px;
            height: 20px;
            border-radius: 50px;
            background: #fffcfc;
          }
          .main-content-question-progress-bar1 {
            position: absolute;
            top: 15px;
            left: 60px;
            height: 20px;
            // width: 20px;
            border-radius: 50px;
            background: #00056a;
          }
        }
        .main-content-question-main {
          width: 100%;
          min-height: 270px;
          background: #fffcfc;
          border-radius: 20px 20px 20px 20px;
          overflow: hidden;
          .main-content-question-main-title {
            height: 30px;
            margin: 30px auto 30px 30px;
          }
          .main-content-question-main-select {
            width: 930px;
            height: 60px;
            border: #cc1d7a 1px solid;
            border-radius: 10px 10px 10px 10px;
            margin: 0 auto;
            padding-left: 30px;
            line-height: 60px;
            margin-bottom: 30px;
            user-select: none;
            cursor: pointer;
          }
          .main-content-question-main-selected {
            width: 930px;
            height: 60px;
            border: #cc1d7a 1px solid;
            border-radius: 10px 10px 10px 10px;
            margin: 0 auto;
            background: #cc1d7a;
            color: #fffcfc;
            padding-left: 30px;
            line-height: 60px;
            margin-bottom: 30px;
            user-select: none;
            cursor: pointer;
          }
        }
      }
      .main-content-buttons {
        width: 1000px;
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        button {
          width: 400px;
          height: 60px;
          //   line-height: 60px;
          text-align: center;
          color: #fffcfc;
          border-radius: 10px 10px 10px 10px;
          border: 0px;
        }
        .main-content-buttons-last {
          background: #00056a;
        }
        .main-content-buttons-next {
          background: #cc1d7a;
        }
      }
      .main-content-footer {
        width: 1000px;
        margin-top: 50px;
        .main-content-footer-lab {
          margin: 0 auto;
          width: 200px;
          height: 32px;
          background: #b3b6ff;
          border-radius: 25px 25px 25px 25px;
          color: #fffcfc;
          text-align: center;
          line-height: 32px;
          margin-bottom: 20px;
        }
        .main-content-footer-list {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin-top: 10px;
          .main-content-footer-list-li {
            width: 220px;
          }
        }
      }
    }
  }
}
</style>