<template>
  <div class="enrolldetail" v-html="html">

  </div>
</template>

<script>
import {enrollmentDetail, informationDetail} from "@/api";

  export default {
    name: "Enrolldetail",
    data(){
      return{
        html: ""
      }
    },
    methods: {
      getEnrollmentDetail(id) {
        enrollmentDetail(id).then(res => {
          if(res.code === 200) {
            this.html = res.data.info;
          }
        })
      },
      getInformationDetail(id) {
        informationDetail(id).then(res => {
          if(res.code === 200) {
            this.html = res.data.info;
          }
        })
      }
    },
    // computed: {
    //   formattedHtml() {
    //     if (!this.html) return '';
    //     return this.html
    //         .replaceAll("\\n", '<br>')
    //         .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    //   }
    // },
    mounted() {
      const urlParams = new URLSearchParams(window.location.search);
      let type = urlParams.get('type');
      let params = {
        recordId: urlParams.get('id')
      }
      if(type === "dynamic") {
        this.getEnrollmentDetail(params);
      } else {
        this.getInformationDetail(params);
      }
    },
  }
</script>

<style scoped lang="scss">
  .enrolldetail {
    margin: 30px auto;
    width: 1280px;
    background-color: white;
    padding: 20px;
  }
</style>