import userAgreement from "@/components/PageFooter/FooterIndex/userAgreement.vue"
import privacyPolicy from "@/components/PageFooter/FooterIndex/Privacy.vue";

const footerIndex = [
    {
        path: "/userAgreement",
        component: userAgreement,
        name: "userAgreement",
        meta: {
            title: '用户协议',
            requiresAuth: false
        }
    }, {
        path: "/privacyPolicy",
        component: privacyPolicy,
        name: "privacyPolicy",
        meta: {
            title: '隐私政策',
            requiresAuth: false
        }
    }
]

export default footerIndex