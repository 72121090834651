<template>
  <div class="arithmetic">
    <div class="title">教你填</div>
    <div class="p_title">1.了解常用志愿填报概念</div>
    <div class="card">
      <el-popover
          v-for="item in teachData"
          :key="item.id"
          placement="top-start"
          :title="item.title"
          width="380"
          trigger="hover"
          :content="item.text">
          <div class="card_item" slot="reference">{{item.title}}</div>
      </el-popover>
    </div>
    <div class="p_title">2.省份最新政策 <span class="p_title_more">更多
    <i class="el-icon-arrow-right"></i>
    </span></div>
    <div class="body">
      <div
          class="card_group_content"
      >
        <div class="card_group_content_link">
          <div class="card_group_content_link_title">
            2024年内蒙古自治区普通高校招生网上填报志愿公告（第42号）专科提前批艺术类…
          </div>
          <div class="card_group_content_link_time">
            2024.05.04
          </div>
          <div class="card_group_content_link_right">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import teachData from "./data/teachData";
export default {
  name: "TeachYouToFillOut",
  data() {
    return {
      dateValue: new Date(),
      teachData
    }
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>

.arithmetic {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;

  .title {
    font-family: Source Han Sans SC, Source Han Sans SC;
    //font-weight: bold;
    font-size: 22px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 30px;
  }

  .p_title {
    width: 100%;
    height: 30px;
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-size: 18px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    line-height: 30px;
    border-left: 4px solid #CC1D7A;
    padding-left: 10px;
    position: relative;

    .p_title_more {
      position: absolute;
      right: 20px;
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 18px;
      color: #AEAEAE;
      text-align: left;
      font-style: normal;
      text-transform: none;

      &:hover {
        color: #CC1D7A;
        cursor: pointer;
      }

    }

  }

  .card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 30px;

    .card_item {
      width: 420px;
      height: 50px;
      margin-top: 20px;
      background: #F1F1F1;
      border-radius: 10px 10px 10px 10px;
      text-align: center;
      line-height: 50px;
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 16px;
      color: #242424;
      font-style: normal;
      text-transform: none;
    }

  }

  .body {
    width: 100%;

    .card_group_content {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 18px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
      width: 100%;
      overflow: hidden;

      ::v-deep .el-table__header > .has-gutter {
        color: #373737;
        font-size: 18px;
        font-weight: 500;
      }

      .card_group_content_link {
        width: 100%;
        height: 80px;
        color: #373737;
        display: flex;
        align-items: center;
        justify-content: space-between; /* 确保子元素之间有空间 */
        border-bottom: 1px solid #bfbfbf;
        cursor: pointer;

        &:hover {
          color: #cc1d7a;
        }

        &:last-child {
          border-bottom: none;
        }

        .card_group_content_link_title {
          flex: 20;
        }

        .card_group_content_link_time {
          flex: 2;
        }

        .card_group_content_link_right {
          text-align: right;
          margin-right: 8px;
          flex: 1;
        }
      }
    }

  }

}

</style>
