import axios from "axios";
const requests = axios.create({
    baseURL: '/api',
    timeout: 5000000,
    headers: { 'Content-Type': 'application/json' }
})
// 请求拦截器
requests.interceptors.request.use((config) => {
    const token = localStorage.getItem("token")
    if (token) {
        config.headers['Authorization'] = token
        return config
    } else {
        return config
    }
})
// 相应拦截器
requests.interceptors.response.use((res) => {
    if (res.data.code == 403) {
        localStorage.clear()
        // window.location.reload()
        return res.data
    } else {
        let headers = res.headers['content-disposition']
        if (headers) {
            return res
        }
        return res.data
    }

}, (error) => {
    Promise.reject(error)
})
export default requests