var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"content-title"},[_vm._v(" 您已答完"+_vm._s(JSON.parse(this.$route.query.data).length)+"题 ")]),_c('div',{staticClass:"cards-list"},_vm._l((_vm.groupScores),function(group,index){return _c('div',{key:index,staticClass:"card",style:({
        backgroundColor:
          index == 0
            ? '#e2e3ff'
            : index == 1
            ? '#dbefff'
            : index == 2
            ? '#CAF6D7 '
            : index == 3
            ? '#FBECCD '
            : '',
      })},[_c('div',{staticClass:"card-title",style:({
          color:
            index == 0
              ? '#00056A'
              : index == 1
              ? '#0B73DB'
              : index == 2
              ? '#009148 '
              : index == 3
              ? '#E69D07 '
              : '',
        })},[_vm._v(" "+_vm._s(index == 0 ? "内向（I）外向（E）" : index == 1 ? "直觉（N）感觉（S）" : index == 2 ? "思考（T）情感（F）" : index == 3 ? "判断（J） 感知（P）" : "")+" ")]),_c('div',{staticClass:"card-info"},[_c('div',[_vm._v("您的"+_vm._s(group.dimensionOne.dimension)+"维度得分")]),_c('div',[_vm._v(_vm._s(group.dimensionOne.score)+"分")])]),_c('div',{staticClass:"card-info"},[_c('div',[_vm._v("您的"+_vm._s(group.dimensionTwo.dimension)+"维度得分")]),_c('div',[_vm._v(_vm._s(group.dimensionTwo.score)+"分")])])])}),0),_c('div',{staticClass:"content-title1"},[_vm._v("您的专属MBTI测试报告已生成")]),_c('div',{staticClass:"info-card"},[_c('div',{staticClass:"card-detail"},[_c('div',{staticClass:"detail-left"},[_c('div',{staticClass:"left-title"},[_vm._v("您的测试类型")]),_c('div',{staticClass:"left-type"},[_vm._v(" "+_vm._s(_vm.answerInfo.type)+" "),_c('span',[_vm._v(_vm._s(_vm.answerInfo.name))])]),_c('div',{staticClass:"left-desc"},[_vm._v(_vm._s(_vm.answerInfo.xxms))])]),_c('div',{staticClass:"detail-right"},_vm._l((_vm.groupScores),function(score,index){return _c('div',{key:index,staticClass:"right-list"},[_c('div',{staticClass:"list-left"},[_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(score.dimensionOne.dimension == "E" ? "外向（E）" : score.dimensionOne.dimension == "S" ? "感觉（S)" : score.dimensionOne.dimension == "T" ? "思考（T)" : score.dimensionOne.dimension == "J" ? "判断（J）" : "")+" ")]),_c('div',{staticClass:"prossgress"},[(score.dimensionOne.score > score.dimensionTwo.score)?_c('div',{staticClass:"prossgress-bar",style:({
                  width: `${(score.dimensionOne.score / 57) * 100}%`,
                  backgroundColor:
                    index == 0
                      ? '#00056A'
                      : index == 1
                      ? '#0B73DB'
                      : index == 2
                      ? '#009148 '
                      : index == 3
                      ? '#E69D07 '
                      : '',
                })}):_vm._e(),(score.dimensionOne.score > score.dimensionTwo.score)?_c('div',{staticClass:"prossgress-bar-info",style:({
                  position: 'absolute',
                  top: '-1px',
                  right: `calc(((${
                    score.dimensionOne.score / 57
                  }) * 100% - 30px)`,
                  backgroundColor:
                    index == 0
                      ? '#00056A'
                      : index == 1
                      ? '#0B73DB'
                      : index == 2
                      ? '#009148 '
                      : index == 3
                      ? '#E69D07 '
                      : '',
                })},[_vm._v(" "+_vm._s(score.dimensionOne.score)+" ")]):_vm._e()])]),_c('div',{staticClass:"list-right"},[_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(score.dimensionTwo.dimension == "I" ? "内向（I）" : score.dimensionTwo.dimension == "N" ? "直觉（N）" : score.dimensionTwo.dimension == "F" ? "情感（F）" : score.dimensionTwo.dimension == "P" ? "感知（P）" : "")+" ")]),_c('div',{staticClass:"prossgress"},[(score.dimensionTwo.score > score.dimensionOne.score)?_c('div',{staticClass:"prossgress-bar",style:({
                  width: `${(score.dimensionTwo.score / 57) * 100}%`,
                  backgroundColor:
                    index == 0
                      ? '#00056A'
                      : index == 1
                      ? '#0B73DB'
                      : index == 2
                      ? '#009148 '
                      : index == 3
                      ? '#E69D07 '
                      : '',
                })}):_vm._e(),(score.dimensionTwo.score > score.dimensionOne.score)?_c('div',{staticClass:"prossgress-bar-info",style:({
                  position: 'absolute',
                  top: '-1px',
                  left: `calc(((${
                    score.dimensionTwo.score / 57
                  }) * 100% - 30px)`,
                  backgroundColor:
                    index == 0
                      ? '#00056A'
                      : index == 1
                      ? '#0B73DB'
                      : index == 2
                      ? '#009148 '
                      : index == 3
                      ? '#E69D07 '
                      : '',
                })},[_vm._v(" "+_vm._s(score.dimensionTwo.score)+" ")]):_vm._e()])])])}),0)])]),_c('div',{staticClass:"card_msg"},[_vm._m(0),_c('div',{staticClass:"msg-list"},[_vm._v(_vm._s(_vm.answerInfo.xgtz)+" ")])]),_c('div',{staticClass:"card_msg"},[_vm._m(1),_c('div',{staticClass:"msg-list"},[_vm._v(_vm._s(_vm.answerInfo.zyqx)+" ")])]),_c('div',{staticClass:"card_msg"},[_vm._m(2),_c('div',{staticClass:"msg-list"},[_vm._v(_vm._s(_vm.answerInfo.xgmd)+" ")])]),_c('div',{staticClass:"card_msg"},[_vm._m(3),_c('div',{staticClass:"msg-list"},[_vm._v(_vm._s(_vm.answerInfo.jjyj)+" ")])]),_c('div',{staticClass:"card_msg"},[_vm._m(4),_c('div',{staticClass:"msg-list"},[_vm._v(_vm._s(_vm.answerInfo.mr)+" ")])]),_c('div',{staticClass:"card_msg"},[_vm._m(5),_c('div',{staticClass:"msg-list"},[_vm._v(_vm._s(_vm.answerInfo.xxms)+" ")])]),_c('div',{staticClass:"card_msg"},[_vm._m(6),_c('div',{staticClass:"msg-list"},[_vm._v(_vm._s(_vm.answerInfo.jjwtms)+" ")])]),_c('div',{staticClass:"card_msg"},[_vm._m(7),_c('div',{staticClass:"msg-list"},[_vm._v(_vm._s(_vm.answerInfo.hjqxx)+" ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',[_vm._v("性格整体特征")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',[_vm._v("职业倾向")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',[_vm._v("性格盲点")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',[_vm._v("解决意见")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',[_vm._v("名人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',[_vm._v("学习模式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',[_vm._v("解决问题模式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',[_vm._v("环境倾向性")])])
}]

export { render, staticRenderFns }