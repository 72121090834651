<template>
  <div class="arithmetic">
    <div class="title">校考日历</div>
    <div class="calendar">
      <el-calendar v-model="dateValue"></el-calendar>
    </div>
  </div>
</template>

<script>
export default {
  name: "SchoolCalendar",
  data() {
    return {
      dateValue: new Date(),
    }
  },
  methods: {
    arithmeticForm() {
      console.log("fto--", this.form)
    },
  }
}
</script>

<style lang="scss" scoped>

.arithmetic {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;

  .title {
    font-family: Source Han Sans SC, Source Han Sans SC;
    //font-weight: bold;
    font-size: 22px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 30px;
  }

  .calendar {
    width: 100%;
    height: 640px;

    ::v-deep .el-calendar__header {
      padding-left: 0;
    }

  }

}

</style>
