<template>
  <div class="arithmetic">
    <div class="title">算投档</div>
    <div class="table">
      <el-form ref="form" :model="form" label-width="100px" label-position="left">
        <el-row justify="space-between">
          <el-col :span="12">
            <el-form-item label="省份">
              <el-select v-model="form.name" placeholder="请选择活动区域" :popper-append-to-body="false">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="form_item_right">
            <el-form-item label="专业类别">
              <el-select v-model="form.name" placeholder="请选择活动区域" :popper-append-to-body="false">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-between">
          <el-col :span="12">
            <el-form-item label="首选科目">
              <el-radio-group v-model="form.resource">
                <el-radio label="物理"></el-radio>
                <el-radio label="历史"></el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="form_item_right">
            <el-form-item label="批次">
              <el-select v-model="form.region" placeholder="请选择活动区域" :popper-append-to-body="false">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-between">
          <el-col :span="12">
            <el-form-item label="文化分">
              <el-input v-model="form.region">
                <div slot="suffix" class="points">分</div>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="form_item_right">
            <el-form-item label="专业分">
              <el-input v-model="form.region">
                <div slot="suffix" class="points">分</div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-button @click="arithmeticForm">开始计算</el-button>
      </el-form>
    </div>
    <div class="castAFile">
      <div class="castAFile_grade">
        投档成绩: 232.11
      </div>
      <div class="castAFile_formula">
        省投档公式：文化分*0.6+专业分
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArithmeticFile",
  data() {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      }
    }
  },
  methods: {
    arithmeticForm() {
      console.log("fto--", this.form)
    },
  }
}
</script>

<style lang="scss" scoped>

.arithmetic {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;

  .title {
    font-family: Source Han Sans SC, Source Han Sans SC;
    //font-weight: bold;
    font-size: 22px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 30px;
  }

  .table {

    ::v-deep .el-input__inner {
      width: 300px;
      height: 45px;
    }

    ::v-deep .el-form-item__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 18px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep .el-button {
      width: 927px;
      height: 45px;
      background-color: #CC1D7A;

      span {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

    }

    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: #CC1D7A;
      background: #CC1D7A;
    }

    ::v-deep .el-radio__inner {
      width: 16px;
      height: 16px;
    }

    ::v-deep .el-color-picker__icon, .el-input, .el-textarea {
      width: 300px;
    }

    ::v-deep .el-radio__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .points {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #AEAEAE;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-right: 5px;
      line-height: 45px;
    }

    ::v-deep .el-input__inner {
      width: 300px;
      height: 45px;
    }

    ::v-deep .el-form-item__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep .el-button {
      width: 927px;
      height: 45px;
      background-color: #CC1D7A;

      span {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

    }

    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: #CC1D7A;
      background: #CC1D7A;
    }

    ::v-deep .el-radio__inner {
      width: 16px;
      height: 16px;
    }

    ::v-deep .el-color-picker__icon, .el-input, .el-textarea {
      width: 300px;
    }

    ::v-deep .el-radio__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep {

      .el-radio__inner:hover {
        border-color: #CC1D7A;
      }

      .el-select-dropdown__item.selected{
        color: #CC1D7A;
      }

      el-input.is-focus .el-input__inner {
        border-color: #CC1D7A;
      }
      .el-input__inner:focus {
        border-color: #CC1D7A;
      }

    }

    .points {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #AEAEAE;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-right: 5px;
      line-height: 45px;
    }

    .form_item_right {
      display: flex;
      justify-content: right;
    }
  }

  .castAFile {
    width: 100%;
    height: 130px;
    margin-top: 37px;
    //background-color: red;

    .castAFile_grade {
      padding-left: 20px;
      width: 100%;
      height: 67px;
      background: #E8E8E8;
      box-sizing: border-box;
      //border-radius: 0px 0px 0px 0px;;
      font-family: Source Han Sans SC, Source Han Sans SC;
      //font-weight: bold;
      font-size: 18px;
      color: #373737;
      line-height: 67px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .castAFile_formula {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 18px;
      color: #373737;
      //line-height: 0px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-top: 15px;
    }

  }

}
</style>