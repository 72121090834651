<template>
  <div class="majorMain">
    <div class="top">
      <div class="img">
        <img src="../../../assets/images/majordetail/img.png" alt="" />
      </div>
      <div class="main">
        <div class="name">{{ detailList.majorName }}</div>
        <div class="info">
          <span v-if="detailList.eduYear">{{ detailList.eduYear }}</span
          ><span v-if="detailList.eduLevel_dictText">{{
            detailList.eduLevel_dictText
          }}</span
          ><span v-if="detailList.parentId_dictText">{{
            detailList.parentId_dictText
          }}</span>
        </div>
      </div>
    </div>
    <div class="tab">
      <div :class="mainIndex == 1 ? 'label' : 'label1'" @click="tab(1)">
        基本介绍
      </div>
      <div :class="mainIndex == 2 ? 'label' : 'label1'" @click="tab(2)">
        就业分析
      </div>
      <div :class="mainIndex == 3 ? 'label' : 'label1'" @click="tab(3)">
        开设院校
      </div>
    </div>
    <div class="line"></div>
    <div v-if="mainIndex == 1" class="content">
      <div class="title" v-if="detailList.introduce">
        <div class="img"></div>
        <div class="name">
          {{ detailList.parentId_dictText }}
        </div>
      </div>
      <div class="text" v-if="detailList.introduce">
        {{ detailList.introduce }}
      </div>
      <div class="title" v-if="detailList.trainPurpose">
        <div class="img"></div>
        <div class="name">培养方向</div>
      </div>
      <div class="text" v-if="detailList.trainPurpose">
        {{ detailList.trainPurpose }}<br />
        <div
          v-if="detailList.trainRequire"
          v-html="detailList.trainRequire"
        ></div>
      </div>
      <div class="title" v-if="detailList.mainClass">
        <div class="img"></div>
        <div class="name">主要课程</div>
      </div>
      <div class="text">{{ detailList.mainClass }}</div>
      <div class="title" v-if="masterWay">
        <div class="img"></div>
        <div class="name">该专业研究方向</div>
      </div>
      <div
        class="text"
        style="display: flex; flex-wrap: wrap; flex-direction: row"
      >
        <span
          style="margin-bottom: 20px"
          v-for="item in masterWay"
          :key="item"
          >{{ item }}</span
        >
      </div>
      <div class="title" v-if="detailList.maleRate || detailList.femaleRate">
        <div class="img"></div>
        <div class="name">数据统计</div>
      </div>
      <div class="text" v-if="detailList.maleRate || detailList.femaleRate">
        <span style="display: flex; width: 300px"
          >男女比例
          <div style="color: #cc1d7a; margin-left: 46px">
            男{{ detailList.maleRate }}% &nbsp;&nbsp;&nbsp;&nbsp; 女{{
              detailList.femaleRate
            }}%
          </div></span
        >
      </div>
      <nodata v-if="detailList.firstMajor == null"></nodata>
    </div>
    <div v-if="mainIndex == 2" class="content">
      <div class="card_group">
        <div v-if="collapseList.length !== 0">
          <div class="card_group_title">
            <img src="@/assets/images/collegedetail/icon_title.png" />
            职业分布
          </div>
          <div
              class="card_group_collapse"
              :style="{ height: item.showDetail ? '120px' : '80px' }"
              v-for="item in collapseList"
              :key="item.recordId"
              @click="collapseClick(item.recordId)"
          >
            <div class="card_group_collapse_top">
              <div class="card_group_collapse_top_left">{{ item.postName }}</div>
              <div class="card_group_collapse_top_right">
                <div class="card_group_collapse_top_right_takeUpAProportionOf">
                  就业占比
                </div>
                <div class="card_group_collapse_top_right_count">
                  {{ item.count }}
                </div>
                <div class="card_group_collapse_top_right_icon">
                  <i
                      :class="
                    item.showDetail
                      ? 'el-icon-arrow-down'
                      : ' el-icon-arrow-right'
                  "
                  ></i>
                </div>
              </div>
            </div>
            <div class="card_group_collapse_bottom">
              <el-progress
                  color="#CC1D7A"
                  :stroke-width="16"
                  :percentage="item.rate"
                  :format="() => ''"
              ></el-progress>
            </div>
            <div class="card_group_collapse_detail" v-if="item.showDetail">
              {{ item.jobDetail }}
            </div>
          </div>
          <el-divider></el-divider>
        </div>

        <div class="card_group_title">
          <img src="@/assets/images/collegedetail/icon_title.png" />
          薪资分布
        </div>

        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            prop="zeroExperience"
            label="应届生"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="twoExperience"
            label="1-3年"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="fiveExperience"
            label="3-5年"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
              prop="tenExperience"
              label="5-10年"
              header-align="center"
              align="center"
          >
          </el-table-column>
        </el-table>

<!--        <el-divider></el-divider>-->
        <div :style="{height: '1px', margin: '24px 0'}"></div>

        <div v-if="areaList.length !== 0">
          <div class="card_group_title">
            <img src="@/assets/images/collegedetail/icon_title.png" />
            就业分布
          </div>

          <div class="card_group_chart">
            <div class="card_group_chart_left">
              <el-progress
                  :width="160"
                  color="#CC1D7A"
                  :stroke-width="20"
                  type="circle"
                  :percentage="specialArea">
              </el-progress>
              <div class="card_group_chart_left_title">北上广深占比</div>
            </div>
            <div class="card_group_chart_right">
              <div
                  class="card_group_chart_right_city"
                  v-for="item in areaList"
                  :key="item.recordId">
                <span :style="{textAlign: 'left'}">{{ item.areaName }}</span>
                <div class="card_group_chart_right_city_progress">
                  <el-progress
                      color="#CC1D7A"
                      :stroke-width="16"
                      :percentage="item.rate"
                  ></el-progress>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-if="mainIndex == 3" class="content">
      <div class="card_group" :style="{padding: 0}">
        <div
          class="school_card"
          v-for="item in schoolList"
          :key="item.recordId"
          @click="toDetail(item.recordId)"
        >
          <img :src="item.collegeLogo" alt="" />
          <div class="school_card_right">
            <div class="school_card_right_top">
              <div class="school_card_right_top_school">
                {{ item.collegeName }}
              </div>
              <div class="school_card_right_top_detail">
                {{ item.provinceName }}{{ item.cityArea }}
              </div>
              <div class="school_card_right_top_rate">
                学校行政归属:{{ item.belongTo }}
                <!--  <span v-for="item1 in item.tagNames" :key="item1">{{
                  item1
                }}</span> -->
              </div>
            </div>

            <div class="school_card_right_bottom">
              <div class="school_card_right_bottom_tagGroup">
                <div class="school_card_right_bottom_tag">
                  {{ item.collegeClassName }}类
                </div>
                <div class="tag1">
                  <span v-for="item1 in item.tagNames" :key="item1">{{
                    item1
                  }}</span>
                </div>
              </div>
              <div class="school_card_right_bottom_rate"></div>
            </div>
          </div>
        </div>
        <nodata v-if="schoolList.length === 0"></nodata>
      </div>
      <el-pagination
          v-if="schoolList.length !== 0"
          background
          layout="prev, pager, next"
          :page-size="pageOptions.pageSize"
          @current-change="handlePageChange"
          :total="pageOptions.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  commonInterface,
  employmentDistribution,
  occupationalDistribution,
  payDistribution,
  queryById3,
  queryOpenCollege
} from "@/api/index";
import nodata from "../../../components/NoData";
export default {
  components: { nodata },
  data() {
    return {
      detailList: {},
      mainIndex: 1,
      pageOptions: {
        pageSize: 10,
        pageNum: 1,
        total: 100,
      },
      collapseList: [],
      tableData: [],
      schoolList: [],
      areaList: [],
      specialArea: 0
    };
  },
  computed: {
    masterWay() {
      if (this.detailList.masterWay) {
        let arr = [];
        arr.push(...this.detailList.masterWay.split("、"));
        return arr;
      }
    },
  },
  mounted() {
    this.getData();
    this.getOccupationalDistribution(this.$route?.query?.id);
    this.getPayDistribution(this.$route?.query?.id);
    this.getEmploymentDistribution(this.$route?.query?.id);
  },
  methods: {
    toDetail(data) {
      let login = this.$store.state.home.userName;
      console.log(login);
      if (login === "登录" || login === "") {
        this.$store.commit("setLoginState", { state: true, islogin: false });
      } else {
        this.$router.push({
          path: "/collegeDetail",
          query: { id: data },
        });
      }
    },
    getSchooleList(recordId) {
      queryOpenCollege(recordId, this.pageOptions.pageNum, this.pageOptions.pageSize).then((res) => {
        this.pageOptions.total = res.data?.total;
        this.schoolList = res.data.list;
      });
    },

    tab(index) {
      this.mainIndex = index;
    },
    getData() {
      let recordId = this.$route.query.id;
      this.getSchooleList(recordId);
      queryById3(recordId).then((res) => {
        this.detailList = res.data;
      });
    },
    collapseClick(id) {
      // 普通效果
      let collapseIndex = this.collapseList.findIndex(item => item.recordId === id);
      this.collapseList[collapseIndex].showDetail = !this.collapseList[collapseIndex].showDetail;

      // 手风琴效果
      // this.collapseList.forEach((item) => {
      //   item.showDetail = item.recordId === id && !item.showDetail;
      // });
    },
    getOccupationalDistribution(major) {
      let search = `@major=${major}`
      // let search = `@major=${1000109}`
      commonInterface("21000645",{search}).then(res => {
        if(res.code === 200) {
          res.data?.rows?.forEach(item => {
            item.showDetail = false;
            item.count = item.rate + "%"
          })
          this.collapseList = res.data.rows;
        }
      });
    },
    getPayDistribution(major) {
      let search = `@major=${major}`
      // let search = `@major=${1000109}`
      commonInterface("21000647", {search}).then(res => {
        if(res.code === 200) {
          this.tableData = res.data?.rows;
        }
      });
    },
    getEmploymentDistribution(major) {
      let search = `@major=${major}`
      // let search = `@major=${1000109}`
      commonInterface("21000646", {search}).then(res => {
        if(res.code === 200) {
          this.areaList = res.data?.rows;

          let specialArea = 0;
          res.data?.rows?.forEach(item => {
            let config = ["北京市","北京","上海市","上海","广州市","广州","深圳市","深圳"]
            config.forEach(configItem => {
              if(configItem === item.areaName) {
                specialArea += item.rate;
              }
            })
          })
          this.specialArea = Math.floor(specialArea);
        }
      });
    },
    handlePageChange(page) {
      this.pageOptions.pageNum = page;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.majorMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  margin-bottom: 60px;
  .top {
    width: 1280px;
    height: 223px;
    border-radius: 20px 20px 20px 20px;
    // background: black;
    background: url("../../../assets/images/majordetail/background.png");
    margin: 30px 0;
    display: flex;
    align-content: center;
    align-items: center;
    position: relative;
    user-select: none;
    .img {
      width: 135px;
      height: 135px;
      margin: 45px 40px;
      background: white;
      border-radius: 50%;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 0px;
      div {
        height: 45px;
        line-height: 45px;
      }
      .name {
        font-size: 21px;
        color: #333;
        margin-bottom: 30px;
        font-weight: bold;
      }
      .label,
      .info {
        display: flex;
        span {
          min-width: 90px;
          height: 46px;
          //   padding: 3px 10px 3px 0;
          text-align: center;
          margin-right: 20px;
          //   color: #707070;
          border-radius: 10px;
          font-size: 18px;
          background: #fff;
          padding: 0 15px;
        }
      }
    }
  }
  .tab {
    display: flex;
    width: 1280px;
    .label {
      width: 240px;
      height: 64px;
      text-align: center;
      line-height: 64px;
      background: #cc1d7a;
      color: #fff;
      margin-right: 32px;
      border-radius: 20px 20px 0 0;
      user-select: none;
      cursor: pointer;
    }
    .label1 {
      width: 240px;
      height: 64px;
      text-align: center;
      line-height: 64px;
      background: #fed7e9;
      //color: #fff;
      color: #cc1d7a;
      margin-right: 32px;
      border-radius: 20px 20px 0 0;
      user-select: none;
      cursor: pointer;
    }
  }
  .line {
    width: 1280px;
    height: 1px;
    border-top: 1px solid #a5a4a4;
    margin-bottom: 30px;
  }
  .content {
    width: 1280px;
    background: #fff;
    display: flex;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;

    .card_group {
      width: 1240px;
      height: 100%;
      border-radius: 20px;
      padding: 20px;

      .card_group_title {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 20px;
        color: #242424;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
        }
      }

      .card_group_collapse {
        width: 100%;
        //background-color: pink;
        margin-bottom: 10px;

        .card_group_collapse_top {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;

          .card_group_collapse_top_left {
            color: #cc1d7a;
          }

          .card_group_collapse_top_right {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 180px;

            .card_group_collapse_top_right_takeUpAProportionOf {
              color: #aeaeae;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 18px;
              line-height: 0px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }

            .card_group_collapse_top_right_count {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 26px;
              color: #cc1d7a;
              line-height: 0px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }

            .card_group_collapse_top_right_icon {
            }
          }
        }

        .card_group_collapse_bottom {
          width: 1285px;
          height: 40px;
          margin-top: 10px;
        }

        .card_group_collapse_detail {
          width: 100%;
          //height: 30px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 18px;
          color: #373737;
          line-height: 27px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }

      .card_group_chart {
        width: 100%;
        display: flex;

        .card_group_chart_left {
          width: 18%;
          text-align: center;
          margin-top: 105px;

          .card_group_chart_left_title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 18px;
            color: #4e4d4d;
            text-align: center;
            font-style: normal;
            text-transform: none;
            margin-top: 10px;
          }
        }

        .card_group_chart_right {
          width: 82%;

          span {
            width: 80px;
            height: 26px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 18px;
            color: #373737;
            text-align: center;
            font-style: normal;
            text-transform: none;
          }

          .card_group_chart_right_city {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            .card_group_chart_right_city_progress {
              width: 92%;
              ::v-deep .el-progress-bar {
                width: 98%;
              }
            }
          }
        }
      }

      .school_card {
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px #bfbfbf solid;
        &:last-child {
          border-bottom: none;
        }

        img {
          width: 135px;
          height: 135px;
        }

        .school_card_right {
          width: calc(100% - 135px);
          height: 135px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .school_card_right_top {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;

            .school_card_right_top_school {
              font-family: Source Han Sans SC, Source Han Sans SC;
              margin-left: 20px;
              font-weight: bold;
              font-size: 32px;
              color: #242424;
              text-align: left;
              font-style: normal;
              text-transform: none;
              flex-grow: 1;
            }

            .school_card_right_top_detail {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 18px;
              color: #373737;
              text-align: left;
              font-style: normal;
              text-transform: none;
              flex-grow: 35;
            }

            .school_card_right_top_rate {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 18px;
              color: #707070;
              text-align: left;
              font-style: normal;
              text-transform: none;
              flex-grow: 2;
            }
          }

          .school_card_right_bottom {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .school_card_right_bottom_tagGroup {
              width: 800px;
              height: 100%;
              /*  display: flex;
              align-items: center; */
              display: flex;
              flex-direction: column;
              span {
                // font-size: 18px;
                padding: 5px 10px;
                background: #f5f5f5;
                margin: 5px;
                // margin-left: 20px;
              }
              .tag1 {
                margin-left: 15px;
              }
              .school_card_right_bottom_tag {
                display: inline-block;
                background: #f5f5f5;
                padding: 5px 10px;
                margin-right: 8px;
                margin-bottom: 10px;
                width: 50px;
                &:first-child {
                  margin-left: 20px;
                }
              }
            }

            .school_card_right_bottom_rate {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 18px;
              color: #707070;
              text-align: left;
              font-style: normal;
              text-transform: none;
              margin-right: 45px;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              span {
                font-size: 18px;
                padding: 5px 10px;
                background: #f5f5f5;
                margin: 5px;
              }
            }
          }
        }
      }
    }

    .title {
      display: flex;
      height: 85px;
      width: 1220px;
      align-items: center;
      .img {
        width: 19px;
        height: 21px;
        background: url("../../../assets/images/majordetail/tip.png");
      }
      .name {
        margin-left: 14px;
        font-size: 18px;
        font-weight: 700;
      }
    }
    .text {
      // max-height: 140px;
      width: 1220px;
      // background: #CC1D7A;
      div {
        ::v-deep p {
          margin-top: 10px;
        }
      }

      span {
        display: block;
        padding: 20px 30px;
        margin-right: 20px;
        background: #fed7e9;
        border-radius: 10px 10px 10px 10px;
        font-size: 18px;
      }
    }

    ::v-deep {

      .number, .btn-prev {
        width: 32px;
        height: 32px;
      }

      .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #CC1D7A;
      }

      .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #CC1D7A;
      }

      .el-pager li {
        line-height: 32px;
      }

    }

  }
}
</style>