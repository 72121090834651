import Vue from 'vue'
import Vuex from 'vuex'
// import user from '@/api/user'
Vue.use(Vuex)

import home from './home/index.js'
import noopsychefill from './noopsychefill/index.js'

import modules from './modules/index.js'
export default new Vuex.Store({
  state() {
    // 数据存储
    return {

    }
  },
  getters: {
  },
  mutations: {

  },
  actions: {
  },
  modules: {
    home,
    noopsychefill,
    modules
  }
})
