import { studentEdit, province, yearly, fillMajor } from '@/api/index.js'
const state = {
    studentedit: {},
    provinceList: {},
    yearlyList: {},
    count: 0,
    add: 0,
    batch: [],
    eduLevelId: 0,
    fillmajorlist: [],
    province: {
        id: "",
        name: ""
    }
}
const mutations = {
    add(state, payload) {
        console.log('p', payload);
        state.add = payload + 1
    },
    province(state, payload) {
        state.province.id = payload.id;
        state.province.name = payload.name;
        localStorage.setItem('province', JSON.stringify(state.province))
    },
    increment(state, payload) {
        state.count = payload.amount;
    },
    batch(state, payload) {
        state.batch = payload.amount
        localStorage.setItem('batch', JSON.stringify(state.batch))
    },
    EDIT(state, studentedit) {
        state.studentedit = studentedit
    },
    PROVINCELIST(state, provinceList) {
        state.provinceList = provinceList.reverse()
    },
    YEARLYLIST(state, yearlyList) {
        state.yearlyList = yearlyList
    },
    FILLMAJOR(state, fillmajorlist) {
        state.fillmajorlist = fillmajorlist
    }
}
const actions = {
    async studentedit({ commit }, data) {
        let result = await studentEdit(data)
        if (result.code === 200) {
            commit('EDIT', result.data)
        } else {
            return Promise.reject(new Error(result.msg))
        }
    },
    async getProvinceList({ commit }) {
        let result = await province()
        if (result.code === 200) {
            commit("PROVINCELIST", result.data)
        } else {
            return
        }
    },
    async getYearlyList({ commit }) {
        let result = await yearly()
        if (result.code == 200) {
            commit("YEARLYLIST", result.data)
        } else {
            return 
        }
    },
    async getfillMajor({ commit }, data) {
        let eduLevelId = data.eduLevelId
        let artWardId = data.artWardId
        let result = await fillMajor(eduLevelId, artWardId)

        if (result.code === 200) {
            commit("FILLMAJOR", result.data)
        } else {

        }
    }
}
const getters = {}

export default {
    state,
    mutations,
    actions,
    getters
}