<template>
  <div>
    <div class="undefine-box">
      <div class="undefine-content">
  
       
      </div>
      <div class="undefine-subtitle">功能开发中，敬请期待...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Undefine",
};
</script>
<style lang="scss" scoped>
.undefine-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
    align-items: center;

  .undefine-content {
    margin-top: 50px;
    width: 360px;
    height: 285px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../assets/images/homeView/undefine.png') no-repeat center center;
    background-size: 100% 100%;
  }
}
</style>
