<template>
  <div class="arithmetic">
    <div class="title">算投档</div>
    <div class="table">
      <el-form ref="form" :model="form" label-width="100px" label-position="left">
        <el-row justify="space-between">
          <el-col :span="12">
            <el-form-item label="省份">
              <el-select v-model="form.region" placeholder="请选择省份" :popper-append-to-body="false">
                <el-option
                    v-for="item in regionSel"
                    :key="item.recordId"
                    :label="item.abbrName"
                    :value="item.recordId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="form_item_right">
            <el-form-item label="专业类别">
                <el-cascader
                    v-model="form.profession"
                    :options="professionSel"
                    :props="{
                      expandTrigger: 'hover',
                      value: 'recordId',
                      label: 'artName',
                      children: 'children',
                      checkStrictly: false,
                      emitPath: true,
                     }"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
<!--        <el-row justify="space-between">-->
<!--          <el-col :span="12">-->
<!--            <el-form-item label="首选科目">-->
<!--              <el-radio-group v-model="form.subject">-->
<!--                <el-radio-->
<!--                    v-for="item in subjectOption"-->
<!--                    :key="item.recordId"-->
<!--                    :label="item.subjectName"-->
<!--                    :value="item.recordId">-->
<!--                </el-radio>-->
<!--              </el-radio-group>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="12" class="form_item_right">-->
<!--            <el-form-item label="批次">-->
<!--              <el-select v-model="form.region" placeholder="请选择活动区域" :popper-append-to-body="false">-->
<!--                <el-option label="区域一" value="shanghai"></el-option>-->
<!--                <el-option label="区域二" value="beijing"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
        <el-row justify="space-between">
          <el-col :span="12">
            <el-form-item label="文化分">
              <el-input type="number" v-model="form.culturalScore" @blur="verify(1)">
                <div slot="suffix" class="points">分</div>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="form_item_right">
            <el-form-item label="专业分">
              <el-input type="number" v-model="form.professionalScore" @blur="verify(2)">
                <div slot="suffix" class="points">分</div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-button @click="arithmeticForm">开始计算</el-button>
      </el-form>
    </div>
    <div class="castAFile">
      <div class="castAFile_grade">
        投档成绩: {{dataInfo.comScore}}
      </div>
      <div class="castAFile_formula">
        省投档公式：{{dataInfo.examRule}}
      </div>
    </div>
  </div>
</template>

<script>
import {arithmeticFile, artexam, province, subject} from "@/api";

export default {
  name: "ArithmeticFile",
  data() {
    return {
      form: {
        region: '', // 省份
        profession: '', // 专业类别
        // subject: '', // 首选科目
        // lot: '', // 批次
        culturalScore: null, //文化分
        professionalScore: null, //专业分
      },
      regionSel: [],
      professionSel: [],
      subjectOption: [],
      dataInfo: {},
    }
  },
  methods: {
    verify(data) {
      if(data === 1) {
        if(this.form.culturalScore < 0 || this.form.culturalScore > 750) {
          this.$notify({
            type: "warning",
            title: "提示",
            message: "请输入0-750分之间成绩",
            showClose: false,
          });
          this.form.culturalScore = null;
        }
      } else {
        if(this.form.professionalScore < 0 || this.form.professionalScore > 300) {
          this.$notify({
            type: "warning",
            title: "提示",
            message: "请输入0-300分之间成绩",
            showClose: false,
          });
          this.form.professionalScore = null;
        }
      }
    },
    arithmeticForm() {
      let flag = false;
      for(let i in this.form) {
        if(this.form[i] === null || this.form[i] === "") {
          flag = true;
        }
      }
      if(flag) {
        this.$notify({
          type: "warning",
          title: "提示",
          message: "请补充信息",
          showClose: false,
        });
      }
      this.getArithmeticFile();
    },
    getRegion() {
      province().then(res => {
        if(res.code === 200) {
          this.regionSel = res.data?.reverse();
        }
      })
    },
    getartexam() {
      let province = this.form.region;
      artexam(province).then(res => {
        if(res.code === 200) {
          this.professionSel = res.data;
          console.log("res=-===",this.professionSel)
        }
      })
    },
    getSubject() {
      subject().then(res => {
        if(res.code === 200) {
          res.data?.forEach(item => {
            if(item.subjectType === '1') {
              this.subjectOption.push(item)
            }
          })
        }
      })
    },
    getArithmeticFile() {
      let province = this.form.region;
      let artWard = this.form.profession[0];
      let subWard = this.form.profession[1] || "";
      let cultureScore = this.form.culturalScore;
      let majorScore = this.form.professionalScore;
      arithmeticFile(province, artWard, subWard, cultureScore, majorScore).then(res => {
        if(res.code === 200) {
          this.dataInfo = res.data;
        }
      })
    }
  },
  mounted() {
    this.getRegion();
    this.getSubject();
  },
  watch: {
    'form.region': {
      handler(newVal, oldVal) {
        this.professionSel = [];
        this.form.profession = "";
        this.getartexam();
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>

.arithmetic {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;

  .title {
    font-family: Source Han Sans SC, Source Han Sans SC;
    //font-weight: bold;
    font-size: 22px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 30px;
  }

  .table {

    ::v-deep .el-input__inner {
      width: 300px;
      height: 45px;
    }

    ::v-deep .el-form-item__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 18px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep .el-button {
      width: 927px;
      height: 45px;
      background-color: #CC1D7A;

      span {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

    }

    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: #CC1D7A;
      background: #CC1D7A;
    }

    ::v-deep .el-radio__inner {
      width: 16px;
      height: 16px;
    }

    ::v-deep .el-color-picker__icon, .el-input, .el-textarea {
      width: 300px;
    }

    ::v-deep .el-radio__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .points {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #AEAEAE;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-right: 5px;
      line-height: 45px;
    }

    ::v-deep .el-input__inner {
      width: 300px;
      height: 45px;
    }

    ::v-deep .el-form-item__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep .el-button {
      width: 927px;
      height: 45px;
      background-color: #CC1D7A;

      span {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

    }

    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: #CC1D7A;
      background: #CC1D7A;
    }

    ::v-deep .el-radio__inner {
      width: 16px;
      height: 16px;
    }

    ::v-deep .el-color-picker__icon, .el-input, .el-textarea {
      width: 300px;
    }

    ::v-deep .el-radio__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep {

      .el-radio__inner:hover {
        border-color: #CC1D7A;
      }

      .el-select-dropdown__item.selected{
        color: #CC1D7A;
      }

      el-input.is-focus .el-input__inner {
        border-color: #CC1D7A;
      }
      .el-input__inner:focus {
        border-color: #CC1D7A;
      }

    }

    .points {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #AEAEAE;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-right: 5px;
      line-height: 45px;
    }

    .form_item_right {
      display: flex;
      justify-content: right;
    }
  }

  .castAFile {
    width: 100%;
    height: 130px;
    margin-top: 37px;
    //background-color: red;

    .castAFile_grade {
      padding-left: 20px;
      width: 100%;
      height: 67px;
      background: #E8E8E8;
      box-sizing: border-box;
      //border-radius: 0px 0px 0px 0px;;
      font-family: Source Han Sans SC, Source Han Sans SC;
      //font-weight: bold;
      font-size: 18px;
      color: #373737;
      line-height: 67px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .castAFile_formula {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 18px;
      color: #373737;
      //line-height: 0px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-top: 15px;
    }

  }

}
</style>