<template>
  <div class="content-container">
    <PageHeader />
    <div class="page-pot">
      <router-view />
    </div>
    <OpVip v-if="opvip == 1"></OpVip>
    <PerfectInformation v-if="first"></PerfectInformation>
    <PageFooter
      v-if="
        this.$route.name !== 'noopsychefill' && this.$route.name !== 'detail'
      "
    />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader/index.vue";
import PageFooter from "@/components/PageFooter/index.vue";
import PerfectInformation from "../HomeView/PerfectInformation/index.vue";
import OpVip from "@/components/vip/OpenedVip/index.vue";
import { getuserlist, queryById1 } from "@/api";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
export default {
  name: "Layout",
  components: {
    PageHeader,
    PageFooter,
    PerfectInformation,
    OpVip
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      mocScore: 0,
      first: false,
      opvip:0
    };
  },
  watch: {
    "$store.state.home.userId": {
      handler(newVal, oldVal) {
        this.getuserlist();
      },
      deep: true,
    },
    "$store.state.home.driver": {
      handler(newVal, oldVal) {
        console.log("driver", newVal, oldVal);
        if (newVal == 1) {
          this.first = true;
        } else if (newVal == 2) {
          this.first = false;
          this.startIntro();
        } else if (newVal == 0) {
          this.first = false;
          // this.getuserlist()
          console.log("driver", newVal, oldVal);
        }
      },
      deep: true,
    },
    "$store.state.home.loginState":{
      handler(newVal, oldVal) {
        if (newVal.islogin == true) {
          this.getuserlist()
        }
      },
      deep: true,
    },
    "$store.state.home.opVip": {
      handler(newVal, oldVal) {
        if (newVal == 1) {
          this.opvip = 1;
        } else {
          this.opvip = 0;
        }
      }
    }
  },
  mounted() {
    this.getuserlist();
    // window.addEventListener('beforeunload', this.close)
    // window.addEventListener('beforeunload',this.close)
  },
  updated() {
    this.getuserlist();
  },
  methods: {
    close(event) {
      console.log(event);
      event.preventDefault();
      if (event.type === "beforeunload") {
        
      }
    },
    getuserlist() {
      console.log(122);
      let userId = localStorage.getItem("userId");
      if (userId) {
        getuserlist(userId).then((res) => {
          let userList = res.data[0];
          if (userList.cardInfo === null) {
            this.$store.commit("isVip", 0);
            localStorage.setItem("isVip", "0");
          } else {
            this.$store.commit("isVip", 1);
            localStorage.setItem("isVip", "1");
          }          
          localStorage.setItem("userList", JSON.stringify(userList));
          if (
            !userList.artWard ||
            !userList.firstMajor ||
            !userList.majorScore ||
            !userList.province
          ) {
            this.$store.commit("Driver", 1);
          } else {
            this.mocScore = userList.comScore;
          }
          queryById1(res.data[0].recordId).then((res) => {
            console.log("学生信息", res);
            localStorage.setItem("studentDetail", JSON.stringify(res.data));
            this.$store.commit("comScore", 1)
          });
        });
      }
    },
    startIntro() {
      this.$router.push({
        name: "home",
      });
      const driverObj = driver({
        showButtons: ["next", "close"],
        showProgress: true,
        nextBtnText: "下一步",
        prevBtnText: "上一步",
        doneBtnText: "完成",
        progressText: "{{current}} / {{total}}",
        steps: [
          {
            element: ".menu-box-1",
            popover: {
              title: "找大学！",
              description: "您可以在这里找到想要的大学！",
              side: "bottom",
            },
          },
          {
            element: ".menu-box-2",
            popover: {
              title: "查专业！",
              description: "您可以在这里查询您所需要的专业！",
              side: "bottom",
            },
          },
          {
            element: ".menu-box-3",
            popover: {
              title: "智能填报！",
              description: "根据个人偏好，为您自动生成报考志愿表！",
              side: "bottom",
            },
          },
          {
            element: ".menu-box-4",
            popover: {
              title: "模拟填报！",
              description: "根据个人条件，手动筛选院校进行模拟填报！",
              side: "bottom",
            },
          },
          {
            element: ".login",
            popover: {
              title: "个人中心",
              description: "将鼠标悬浮，则显示个人中心列表，点击进入个人中心！",
              side: "left",
            },
          },
          {
            element: ".simulate",
            popover: {
              title: "模拟填报！",
              description: "根据个人条件，手动筛选院校进行模拟填报！",
              side: "left",
            },
          },
          {
            element: ".noopsyche",
            popover: {
              title: "智能填报！",
              description: "根据个人偏好，为您自动生成报考志愿表！",
              side: "right",
              onNextClick: () => {
                this.$router.push({
                  name: "noopsychefill",
                });
                driverObj.moveNext();
              },
            },
          },
          {
            popover: {
              description:
                "<span style='font-size: 15px; display: block; margin-top: 10px; text-align: center;'>接下来为您介绍智能填报！</span>",
            },
          },
          {
            element: ".div-form",
            popover: {
              title: "智能填报！",
              description: "在这里可以完善您的信息！",
            },
          },
          {
            element: ".noopsyche",
            popover: {
              // title: "智能填报！",
              description: "填写完信息后进入下一步！",
              onNextClick: () => {
                this.$store.commit("increment", { amount: 1 });
                this.$store.commit("add", 0);
                driverObj.moveNext();
              },
            },
          },
          {
            popover: {
              description:
                "<span style='font-size: 15px; display: block; margin-top: 10px; text-align: center;'>这里是选择批次页面！</span>",
            },
          },
          {
            element: ".table-li",
            popover: {
              // title: "智能填报！",
              description: "选择报考的批次！",
              onNextClick: () => {
                driverObj.moveNext();
                this.$store.commit("add", 1);
                this.$store.commit("increment", { amount: 2 });
              },
            },
          },
          {
            popover: {
              description:
                "<span style='font-size: 15px; display: block; margin-top: 10px; text-align: center;'>这里是个人偏好设置页面！</span>",
            },
          },
          {
            element: ".preference-page-inner-box",
            popover: {
              // title: "智能填报！",
              description: "选择您的个人偏好！",
            },
          },
          {
            element: ".preference-page-btn",
            popover: {
              // title: "智能填报！",
              description: "点击进入志愿表页面！",
              onNextClick: () => {
                this.$store.commit("add", 2);
                this.$store.commit("increment", { amount: 3 });
                driverObj.moveNext();
              },
            },
          },
          {
            popover: {
              description:
                '<span style="font-size: 15px; display: block; margin-top: 10px; text-align: center;">这里是填报页面！</span>',
            },
          },
          {
            element: ".el-row",
            popover: {
              description: "可以在这里根据录取率筛选院校！",
            },
          },
          {
            element: ".top-right",
            popover: {
              description: "这里可以把志愿表保存到我的志愿表中！",
            },
          },
          {
            element: ".del",
            popover: {
              description: "这里可以删除院校或者拖动院校！",
              onNextClick: () => {
                this.$router.push({ name: "simulatefill" });
                driverObj.moveNext();
              },
            },
          },
          {
            popover: {
              description: "这里是模拟填报页面！",
            },
          },
          {
            element: ".filter-main",
            popover: {
              title: "模拟填报！",
              description: "可以在这里手动筛选院校进行模拟填报！",
            },
          },
          {
            element: ".inquire ",
            popover: {
              description: "设置好条件后点击这里进行院校筛选！",
            },
          },
          {
            element: ".allin",
            popover: {
              description: "点击这里将该院校添加到填报表中！",
              onNextClick: () => {
                this.$router.push({
                  name: "home",
                });
                driverObj.moveNext();
              },
            },
          },
          {
            popover: {
              description: "功能介绍完毕啦，开始使用吧！",
              onNextClick: () => {
                this.$store.commit("Driver", 2);
                driverObj.destroy();
              },
            },
          },
        ],
      });
      driverObj.drive();
    },
  },
};
</script>

<style scoped lang="scss">
//引入公共样式表
@import "@/assets/style/businessTheme.scss";

.content-container {
  // width: 100%;
  //background-color: $page-back-main-color;
  background: linear-gradient( 180deg, #FFE5DC 0%, #F5F5F5 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  .remind {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 500;
    background: rgba(0, 0, 0, 0.5);
    .first {
      width: 100%;
      height: 100%;
    }
    .first-title {
      width: 320px;
      height: 56px;
      background: rgba(255, 255, 255, 0.1);
      position: absolute;
      top: 50px;
      left: 50%;
      z-index: 501;
    }
  }
  .page-pot {
    width: 100%;
    z-index: 2;
    min-height: calc(100vh - 380px);
  }
  .personal-score {
    z-index: 1;
    user-select: none;
    cursor: pointer;
    writing-mode: vertical-rl;
    text-align: center;
    line-height: 50px;
    width: 50px;
    height: 222px;
    background: #cc1d7a;
    color: #fff;
    border-radius: 0px 20px 20px 0px;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translate(0%, -50%);
    span {
      transform: rotate(-90deg);
      writing-mode: vertical-lr;
    }
  }
}
</style>
