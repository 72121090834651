<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  list-style: none;
}
#app {
  // max-width:1920px;
  min-width: 1500px;
  min-height: 10vh;
  margin: 0 auto;
  font-family: "Microsoft YaHei", "微软雅黑", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
</style>
