<template>
  <div class="collegedetail">
    <div class="title">
      <div class="img">
        <img :src="detailList.collegeLogo" alt="" />
      </div>
      <div class="main">
        <div class="name">{{ detailList.collegeName }}</div>
        <div class="label">
          <span v-for="item in detailList.tagNames" :key="item">{{
            item
          }}</span>
        </div>
        <div class="info">
          <span>{{ detailList.province_dictText }}</span
          ><span>{{ detailList.eduLevel_dictText }}</span
          ><span>{{ detailList.natureType_dictText }}</span>
        </div>
      </div>
      <div class="add" @click="addSchool(detailList.recordId)">
        <img src="../../../assets/images/collegedetail/add.png" alt="" />
        {{ isConcern ? "已关注" : "关注" }}
      </div>
    </div>
    <div class="main">
      <div class="top">
        <div class="tab">
          <div :class="mainIndex == 1 ? 'label' : 'label1'" @click="tab(1)">
            基本介绍
          </div>
          <div :class="mainIndex == 2 ? 'label' : 'label1'" @click="tab(2)">
            招录信息
          </div>
          <div :class="mainIndex == 3 ? 'label' : 'label1'" @click="tab(3)">
            招生数据
          </div>
          <div :class="mainIndex == 4 ? 'label' : 'label1'" @click="tab(4)">
            录取分析
          </div>
        </div>
        <div class="line"></div>
      </div>
      <div v-if="mainIndex == 1" class="content">
        <div class="left">
          <div class="top">
            <div class="lebs">
              <div
                class="leb"
                :title="'创建时间：' + this.detailList.establishYear"
              >
                <img
                  src="../../../assets/images/collegedetail/1.png"
                  alt=""
                /><span
                  >创建时间：{{
                    this.detailList.establishYear
                      ? this.detailList.establishYear
                      : "——"
                  }}</span
                >
              </div>
              <div
                class="leb"
                :title="'学校性质：' + detailList.natureType_dictText"
              >
                <img src="../../../assets/images/collegedetail/2.png" alt="" />
                <span>学校性质：{{ detailList.natureType_dictText }}</span>
              </div>
              <div
                class="leb"
                :title="'学历层次：' + detailList.eduLevel_dictText"
              >
                <img
                  src="../../../assets/images/collegedetail/3.png"
                  alt=""
                /><span>学历层次：{{ detailList.eduLevel_dictText }}</span>
              </div>
              <div
                class="leb"
                :title="'院校类型：' + detailList.collegeClasz_dictText"
              >
                <img
                  src="../../../assets/images/collegedetail/4.png"
                  alt=""
                /><span>院校类型：{{ detailList.collegeClasz_dictText }}</span>
              </div>
              <div
                class="leb"
                :title="
                  '所在地：' +
                  detailList.province_dictText +
                  detailList.cityArea
                "
              >
                <img
                  src="../../../assets/images/collegedetail/5.png"
                  alt=""
                /><span
                  >所在地：{{ detailList.province_dictText
                  }}{{ detailList.cityArea }}</span
                >
              </div>
              <div class="leb" :title="'主管部门：' + detailList.belongTo">
                <img
                  src="../../../assets/images/collegedetail/6.png"
                  alt=""
                /><span>主管部门：{{ detailList.belongTo }}</span>
              </div>
              <div
                class="leb"
                :title="
                  '博士点：' + detailList.doctorFst + detailList.doctorSec
                "
              >
                <img
                  src="../../../assets/images/collegedetail/7.png"
                  alt=""
                /><span
                  >博士点：{{
                    detailList.doctorFst + detailList.doctorSec
                  }}</span
                >
              </div>
              <div
                class="leb"
                :title="
                  '硕士点：' + detailList.masterFst + detailList.masterSec
                "
              >
                <img
                  src="../../../assets/images/collegedetail/8.png"
                  alt=""
                /><span
                  >硕士点：{{
                    detailList.masterFst + detailList.masterSec
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="left-main">
            <div
              v-html="detailList.introduce ? detailList.introduce : '暂无内容'"
            ></div>
          </div>
          <div class="card_img" v-if="imgList.length > 0">
            <div class="card_group_title">
              <img src="@/assets/images/collegedetail/icon_title.png" />
              院校风采
            </div>
            <div class="card_list">
              <el-carousel :interval="4000" type="card"  height="400px">
                <el-carousel-item v-for="item in imgList" :key="item">
                  <h3 class="medium"><img :src="item.collegeImg" style="width: 100%; height: auto;" alt=""></h3>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="cardInfo">
              <div class="wangzhi">官方网址：<span @click="toWeb(detailList.website)">{{detailList.website}}</span></div>
              <div class="wangzhi">电话咨询：{{detailList.recruitPhone}}</div>
              <div class="wangzhi">校区地址：{{detailList.address}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="mainIndex == 2" class="content">
        <div class="left">
          <div class="card_group">
            <div v-if="schoolexamInfo.length !== 0">
              <div class="card_group_title">
                <img src="@/assets/images/collegedetail/icon_title.png"/>
                校考信息
                <div class="card_group_title_right" @click="collClick">
                  {{collationExaminationStu ? "收起" : "展开"}}
                </div>
              </div>

              <div
                  class="card_group_content"
                  :style="{height: collationExaminationStu ? 'auto' : '300px'}"
                  v-html="schoolexamInfo">
              </div>

              <el-divider></el-divider>
            </div>

            <div class="card_group_title" :style="{ margin: 0 }">
              <img src="@/assets/images/collegedetail/icon_title.png" />
              招生信息
            </div>
            <div
              class="card_group_content"
              v-for="item in enrollList"
              :key="item.recordId"
              @click="detailClick(item.recordId)"
            >
              <div class="card_group_content_link">
                <div class="card_group_content_link_title">
                  {{ item.title }}
                </div>
                <div class="card_group_content_link_time">
                  {{ item.infoDate }}
                </div>
                <div class="card_group_content_link_right">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="mainIndex == 3" class="content">
        <div class="left">
          <div class="card_group">
            <div class="card_group_title">
              <img src="@/assets/images/collegedetail/icon_title.png" />
              近5年招生计划数
            </div>
            <div class="chart-container">
              <MercuryChart :options="options1" />
            </div>

            <el-divider></el-divider>

            <div class="card_group_title">
              <img src="@/assets/images/collegedetail/icon_title.png" />
              近5年校考报名人数
            </div>

            <div class="chart-container">
              <MercuryChart :options="options2" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="mainIndex == 4" class="content">
        <div class="left">
          <div class="top">
            <div class="leb">
              <div class="card_group">
                <!--                <div class="card_group_title">-->
                <!--                  <img src="@/assets/images/collegedetail/icon_title.png"/>-->
                <!--                  考试院投档-->
                <!--                </div>-->
                <!--                <div class="card_group_content_text">-->
                <!--                  录取批次：本科A批-->
                <!--                </div>-->
                <!--                <div class="card_group_content_text">-->
                <!--                 批次线：288分（2024年）达线-->
                <!--                </div>-->
                <!--                <div class="card_group_content_text">-->
                <!--                  投档规则：-->
                <!--                </div>-->

                <!--                <el-divider></el-divider>-->

                <div class="card_group_title">
                  <img src="@/assets/images/collegedetail/icon_title.png" />
                  专业招生要求
                </div>
                <div class="card_group_content">
                  <el-table :data="tableData" style="width: 100%">
                    <el-table-column
                      prop="name"
                      label="限制条件"
                      header-align="center"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="require"
                      label="要求"
                      header-align="center"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="qualify"
                      label="达线状态"
                      header-align="center"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <span :style="{ color: '#CC1D7A' }">{{
                          scope.row.qualify === 1 ? "达线" : "不达线"
                        }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

<!--                <el-divider></el-divider>-->

                <div :style="{height: '1px', margin: '24px 0'}"></div>

                <div class="card_group_title">
                  <img src="@/assets/images/collegedetail/icon_title.png" />
                  历年投档情况
                </div>

                <div class="card_group_content">
                  <el-table :data="tableData2" style="width: 100%">
                    <el-table-column
                      prop="yearly"
                      label="年份"
                      header-align="center"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="planNums"
                      label="计划数"
                      header-align="center"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="minComScore"
                      label="投档最低分"
                      header-align="center"
                      align="center"
                    >
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryById2,
  collect,
  isCollect,
  enrollmentInformation,
  enrollmentPlan,
  numberOfApplicants,
  filingSituation,
  institutionInformation,
  admissionRequirements,
  institutionStyle,
  getuserlist,
} from "@/api/index";
import MercuryChart from "@/components/MercuryChart/index.vue";
import Nodata from "@/components/NoData/index.vue";
export default {
  name: "CollegeDetail",
  components: {
    Nodata,
    MercuryChart,
  },
  data() {
    return {
      detailList: {},
      mainIndex: 1,
      isConcern: false,
      echartsData1: [],
      echartsData2: [],
      tableData: [],
      tableData2: [],
      enrollList: [],
      imgList: [],
      schoolexamInfo: "",
      collationExaminationStu: false
    };
  },
  mounted() {
    this.getData();
    this.getisCollect();
    this.getEnrollmentInformation();
    this.getEnrollmentPlan(this.$route.query.id);
    this.getNumberOfApplicants(this.$route.query.id);
    this.getFilingSituation(this.$route.query.id);
    this.getAdmissionRequirements(this.$route.query.id);
    this.getSchoolImg();
  },
  methods: {
    // 跳转官网
    toWeb(data) {
    window.open(data, '_blank');
    },
    collClick() {
      this.collationExaminationStu = !this.collationExaminationStu;
    },
    // 获取院校风采
    getSchoolImg() {
      console.log(this.$route.query.id);
      let id = this.$route.query.id;
      let search = `@college=${id}`;
      let data = {
        search,
      };
      institutionStyle(data).then((res) => {
        if (res.code === 200) {
          this.imgList = res.data.rows;
        }
      });
    },
    getisCollect() {
      let college = this.$route.query.id;
      isCollect(college).then((res) => {
        this.isConcern = res.data;
      });
    },
    addSchool(data) {
      let college = data;
      collect(college).then((res) => {
        if (res.code == 200) {
          this.getisCollect();
        } else if (res.code == 403) {
          this.$store.commit("setLoginState", { state: true, islogin: false });
        }
      });
    },
    tab(index) {
      this.mainIndex = index;
    },
    getData() {
      let recordId = this.$route.query.id;
      queryById2(recordId).then((res) => {
        this.detailList = res.data;
        this.schoolexamInfo = res.data?.schoolexamInfo;
      });
    },
    getEnrollmentInformation() {
      let id = this.$route.query?.id;
      let search = `@college=${id}`;
      let data = {
        search,
      };
      institutionInformation(data).then((res) => {
        if (res.code === 200) {
          this.enrollList = res.data.rows;
        }
      });
    },
    detailClick(recordId) {
      window.open(`/enrolldetail?id=${recordId}`, "_blank");
    },
    getEnrollmentPlan(college) {
      enrollmentPlan(college).then((res) => {
        if (res.code === 200) {
          this.echartsData1 = res.data?.reverse();
        }
      });
    },
    getNumberOfApplicants(college) {
      numberOfApplicants(college).then((res) => {
        if (res.code === 200) {
          this.echartsData2 = res.data?.reverse();
        }
      });
    },
    getFilingSituation(college) {
      filingSituation(college).then((res) => {
        if (res.code === 200) {
          this.tableData2 = res.data;
        }
      });
    },
    getAdmissionRequirements(college) {
      let student = JSON.parse(localStorage.getItem("userList"))?.recordId;
      admissionRequirements(college, student).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
        }
      });
    },
  },
  computed: {
    options1() {
      return {
        title: {
          subtext: "（人数）",
          subtextAlign: "left",
          left: "-7px",
          top: "-5px",
        },
        xAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
          },
          data: this.echartsData1?.map((item) => item?.yearly),
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: 40,
          bottom: 30,
          left: 40,
          right: 30,
        },
        series: [
          {
            data: this.echartsData1?.map((item) => item?.nums),
            type: "line",
            itemStyle: {
              color: "#CC1D7A", // 设置线条颜色
            },
            symbol: "circle", // 设置为圆形
            symbolSize: 10, // 设置圆点大小
          },
        ],
      };
    },
    options2() {
      return {
        title: {
          subtext: "（人数）",
          subtextAlign: "left",
          left: "-7px",
          top: "-5px",
        },
        xAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
          },
          data: this.echartsData2?.map((item) => item?.yearly),
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: 40,
          bottom: 30,
          left: 40,
          right: 30,
        },
        series: [
          {
            data: this.echartsData2?.map((item) => item?.nums),
            type: "line",
            itemStyle: {
              color: "#CC1D7A", // 设置线条颜色
            },
            symbol: "circle", // 设置为圆形
            symbolSize: 10, // 设置圆点大小
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.collegedetail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    width: 1280px;
    height: 223px;
    margin-top: 30px;
    margin-bottom: 40px;
    background: linear-gradient(86deg, #ffffff 0%, #ffffff 48%, #ff8ac0 100%);
    border-radius: 5px;
    display: flex;
    align-content: center;
    align-items: center;
    position: relative;
    user-select: none;
    .img {
      width: 135px;
      height: 135px;
      margin: 45px 40px;
      // background: #ccc;
      // border-radius: 50%;
      img {
        // width: 135px;
        height: 135px;
        // margin: 45px 40px;
        background: #ccc;
        // border-radius: 50%;
      }
    }
    .main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 0px;
      div {
        height: 45px;
        line-height: 45px;
      }
      .name {
        font-size: 21px;
        color: #333;
        font-weight: bold;
      }
      .label,
      .info {
        display: flex;
        span {
          padding: 3px 10px 3px 0;
          color: #707070;
          font-size: 18px;
        }
      }
    }
    .add {
      user-select: none;
      cursor: pointer;
      width: 135px;
      height: 50px;
      border: 0;
      background: white;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 50px;
      img {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
    }
  }
  .main {
    width: 1280px;
    margin-bottom: 60px;
    user-select: none;
    .top {
      width: 1280px;
      margin-bottom: 30px;
      .tab {
        display: flex;
        .label {
          width: 240px;
          height: 64px;
          text-align: center;
          line-height: 64px;
          background: #cc1d7a;
          color: #fff;
          margin-right: 32px;
          border-radius: 20px 20px 0 0;
          user-select: none;
          cursor: pointer;
        }
        .label1 {
          width: 240px;
          height: 64px;
          text-align: center;
          line-height: 64px;
          background: #fed7e9;
          //color: #fff;
          color: #cc1d7a;
          margin-right: 32px;
          border-radius: 20px 20px 0 0;
          user-select: none;
          cursor: pointer;
        }
      }
      .line {
        width: 1280px;
        height: 1px;
        border-top: 1px solid #a5a4a4;
      }
    }
    .content {
      width: 1280px;
      display: flex;
      .left {
        width: 1280px;
        min-height: 500px;
        border-radius: 20px;
        background: #fff;
        //margin-right: 32px;
        .card_img {
          // width: 1240px;
          width: 1240px;
          // height: 100%;
          border-radius: 20px;
          padding: 30px;
          .card_group_title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            font-size: 20px;
            color: #242424;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            img {
              margin-right: 10px;
            }

          }
          .card_list {

            .el-carousel__container {
              height: 400px;
            }
            .el-carousel__item h3 {
              color: #475669;
              font-size: 14px;
              opacity: 0.75;
              line-height: 400px;
              margin: 0;
            }

            .el-carousel__item:nth-child(2n) {
              background-color: #99a9bf;
            }

            .el-carousel__item:nth-child(2n + 1) {
              background-color: #d3dce6;
            }
          }
          .cardInfo{
            width: 100%;
            display: flex;
            flex-direction: column;
            .wangzhi{
              width: 100%;
              height: 30px;
              line-height: 30px;
              font-size: 16px;
            }
          }
        }
        .card_group {
          width: 1240px;
          height: 100%;
          border-radius: 20px;
          padding: 30px;

          .chart-container {
            width: 100%;
            height: 400px;
          }

          .card_group_title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            font-size: 20px;
            color: #242424;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            position: relative;

            img {
              margin-right: 10px;
            }

            .card_group_title_right {
              position: absolute;
              right: 10px;
              color: #CC1D7A;
              font-family: Source Han Sans SC, Source Han Sans SC;
            }

          }
          .card_group_content_text {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 18px;
            color: #373737;
            margin-bottom: 10px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }

          .card_group_content {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 18px;
            color: #373737;
            text-align: left;
            font-style: normal;
            text-transform: none;
            width: 100%;
            overflow: hidden;

            ::v-deep .el-table__header > .has-gutter {
              color: #373737;
              font-size: 18px;
              font-weight: 500;
            }

            .card_group_content_link {
              width: 100%;
              height: 80px;
              color: #373737;
              display: flex;
              align-items: center;
              justify-content: space-between; /* 确保子元素之间有空间 */
              border-bottom: 1px solid #bfbfbf;
              cursor: pointer;

              &:hover {
                color: #cc1d7a;
              }

              &:last-child {
                border-bottom: none;
              }

              .card_group_content_link_title {
                flex: 20;
              }

              .card_group_content_link_time {
                flex: 2;
              }

              .card_group_content_link_right {
                flex: 1;
              }
            }
          }
        }

        .lebs {
          width: 1220px;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 30px 30px 20px 30px;
          .leb {
            width: 226px;
            height: 66px;
            border-radius: 10px;
            margin-bottom: 20px;
            padding: 0 20px;
            background: #feecf4;
            display: flex;
            align-items: center;

            cursor: pointer;

            img {
              margin-right: 14px;
            }
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .left-main {
          width: 1280px;
          max-height: 525px;
          overflow-y: scroll;
          scrollbar-color: white;
          scrollbar-color: white;
          padding-bottom: 20px;
          // border-bottom: #f5f5f5 solid 3px;
          div {
            margin: 0px 30px 0px 30px;
            overflow: hidden;
            ::v-deep p {
              margin-bottom: 10px;
              text-indent: 2rem;
              line-height: 30px;
            }
          }
        }
      }
      .right {
        width: 372px;
        .top {
          width: 372px;
          height: 694px;
          border-radius: 20px;
          background: #fff;
          position: relative;
          overflow: hidden;
          .top-title {
            // font-weight: bold;
            font-weight: bold;
            width: 100px;
            height: 35px;
            margin-left: 20px;
            // text-align: center;
            line-height: 35px;
            margin-top: 25px;
          }
          /*   &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 164px;
            height: 164px;
            background: linear-gradient(49deg, #ffffff 25%, #f5a8cc 100%);
            border-radius: 10px 10px 10px 100%;
          } */
        }
        .bottom {
          width: 372px;
          height: 135px;
          border-radius: 20px;
          background: #fff;
          overflow: hidden;
          .title1 {
            font-weight: bold;
            width: 100px;
            height: 35px;
            margin-left: 20px;
            // text-align: center;
            line-height: 35px;
            margin-top: 25px;
          }
          .address {
            // width: 372px;
            margin-top: 15px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>