<!--
  * @FileDescription: 基础图表组件
  * @Author: 段天德
  * @Date: 2025/1/7 15:51
  -->
<template>
  <div ref="chart" :style="chartStyle" class="luck-chart-root">
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {verifyValue} from "@/utils/verify";
import {getFloatNumber} from "@/utils/mathsUtils";
import {resizeObserver} from "@/utils/baseUtil";

export default {
  name: "index",
  props: {
    // echarts 配置项
    options: {
      type: Object
    },
    // 宽度
    width: {
      type: String,
      default: '100%'
    },
    // 高度
    height: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      myChart: null,   // 图表实例
    }
  },
  computed: {
    chartStyle() {
      return {
        width: verifyValue(this.width) ? this.width : '100%',
        height: verifyValue(this.height) ? this.height : '100%'
      }
    }
  },
  watch: {
    options: {
      handler(val) {
        this.resetChart()
      },
      deep: true
    }
  },
  methods: {
    /**
     * 重置图表
     */
    resetChart() {
      this.myChart.setOption(this.options)
    },
    /**
     * 初始化图表
     */
    initChart() {
      this.myChart = echarts.init(this.$refs.chart);
      this.resetChart()
      this.addResizeListener()
      this.loaded()
    },
    /**
     * 监听图表加载完成
     */
    loaded() {
      this.$emit('chartLoaded', this.myChart)
    },
    /**
     * 监听窗口大小变化
     */
    addResizeListener() {
      resizeObserver(this.$refs.chart, entries => {
        const width = entries[0].contentRect.width + 'px';
        const height = entries[0].contentRect.height + 'px';
        this?.myChart?.resize({
          width: getFloatNumber(width),
          height: getFloatNumber(height)
        });
      })
    },
    /**
     * 销毁图表
     */
    destroyChart() {
      this.myChart.dispose();
      this.myChart = null;
    }
  },
  mounted() {
    this.initChart()
  },
  beforeDestroy() {
    this.destroyChart()
  }
}
</script>
