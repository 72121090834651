import _ from "lodash";

/**
 * 校验数据是否有值
 * @param value
 * @returns {boolean}
 */
const verifyValue = value => !_.isNil(value) && value !== "";

/**
 * 校验数组是否有值
 * @param arr
 * @returns {boolean}
 */
const verifyArr = arr => _.isArray(arr) && arr.length > 0;

/**
 * 校验对象是否有值
 * @param obj
 * @returns {boolean}
 */
const verifyObj = obj => _.isObject(obj) && !verifyArr(obj) && !_.isEmpty(obj);

/**
 * 比较两个值是否严格相等
 * @param value           要比较的值
 * @param other           另一个要比较的值
 * @returns {boolean}
 */
const eq = (value, other) => _.eq(value, other)

/**
 * 比较两个值是否值相等
 * @param value           要比较的值
 * @param other           另一个要比较的值
 * @returns {boolean}
 */
const isEqual = (value, other) => value == other || _.isEqual(value, other)

/**
 * 校验是否是DOM元素
 * @param value
 * @returns {boolean}
 */
const isElement = value => _.isElement(value);

/**
 * 校验是否是函数
 * @param value
 * @returns {value is (...args: any[]) => any}
 */
const isFunction = value => _.isFunction(value);

/**
 * 校验是否是外部链接
 * @param {string} path
 * @returns {Boolean}
 */
const isExternal = path => {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export {verifyValue, verifyArr, verifyObj, eq, isEqual, isFunction, isElement, isExternal};
