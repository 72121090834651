const teachData = [
  {
    id: 1,
    title: "平行志愿",
    text: "所谓“平行志愿”就是在每个录取批次的学校中，考生可填报若干个平行的学校，然后按“分数优先、遵循志愿”的原则进行投档录取，改变过去志愿优先的录取原则。具体地说，按照平行志愿录取方式，录取时，将考生按成绩从高分到低分顺序排队，依次检索考生填报的A、B、C、D等几个平行志愿，如果符合A志愿，则被录取，如果分数不够，则继续检索B志愿，依次类推，直到被符合条件的学校录取。"
  },
  {
    id: 2,
    title: "顺序志愿",
    text: "传统志愿，也叫传统梯度志愿、顺序志愿，是指按分数高低划定最低控制分数线，在同一录取批次中志愿优先，对同一层次志愿按分数高低投档。\n" +
      "投档办法：在相同科类同一录取批次内，对每个院校按文科和理科的第一志愿考生先排序，然后按一定的比例[一般是1:(1.1〜1.2)]\n" +
      "由高分到低分向录取院校投档。录取时，在同一批次上线考生中，先录取第一志愿的考生，第一志愿录取结束后，才对第一志愿未被录取的考生进行下一个志愿的录取。已完成招生计划的院校结束本批次招生，未完成招生计划的院校才能录取下一个志愿的考生。"
  },
  {
    id: 3,
    title: "录取批次",
    text: "所谓录取批次，是指高考录取工作在教育部统一领导下，由各省级招生委员会负责组织实施，因为参加录取的高校较多，需要安排不同类型的院校分期分批进行，由此形成了录取批次。根据不同高校情况所划分的顺序录取、录取步骤一般分为以下几个批次：本科提前批、本科第一批、本科第二批、本科第三批、专科提前批、专科普通批，此外，有些省份还设置了“农村专项计划”批次、自主招生批。\n" +
      "分批次录取既有利于不同类型高校招生，也有利于增加考生的录取机会。除提前批外，批次越靠后，批次录取控制分数线会逐批降低，只要正确掌握不同批次及同批次不同顺序的志愿之间的关系，前批次如果不能录取，后批次仍有机会。"
  },
  {
    id: 4,
    title: "志愿优先",
    text: "志愿优先是高校录取学生和分配专业的一种录取方法。在志愿优先的录取方法下，学生录取的先后取决于学生报考的意向，志愿靠前则会被优先考虑。这种录取方法适用于学生被高校录取和进入高校后的专业分配。"
  },
  {
    id: 5,
    title: "分数优先",
    text: "分数优先原则就是将该校调档线上所有学生按高考分数由高到低进行排队，依次按考生所报专业志愿确定录取专业，如果考生所报第一专业已满，顺序考虑这个考生所报第二专业，如果所报几个专业都不能录取，服从专业调剂的考生，有可能被调剂到别的专业，未服从专业调剂的考生被作退档处理。"
  },
  {
    id: 6,
    title: "综合分计算公式",
    text: "院校招生录取中，文化和专业的比例算法。不同省份和院校对于艺术类专业投档录取的分数计算公式可能存在差异，考生要明确自己所在省份或者意向院校制定的艺术类专业综合分计算公式，通常来讲省份的综合分计算公式一般用于投档排序，院校的综合分计算公式用于录取排序，两者都要考虑。"
  },
  {
    id: 7,
    title: "退档",
    text: "院校调阅考生档案数量一般控制在120%以内，由学校具体确定调档比例，考生是否录取及所录专业由学校决定，并负责对未录取考生的解释及其他遗留问题的处理。高校不会无缘无故地退掉高分考生而录取低分考生，一般高分考生被退档，有三种情况：一是考生的分数虽然高于学校的录取分数线，但未达到所报专业的录取专业分数线且又不服从调剂;二是虽然总分较高但相关科目较差，不满足小分要求;三是身体条件不符合所报专业的要求。如果考生被不合理地退档，应及时与有关高校联系，了解退档原因，避免之后由于同类问题导致退档。"
  },
  {
    id: 8,
    title: "滑档",
    text: "滑档用简单的解释就是，原本能够就读一本批次的，因为政策或退档等原因只能就读下一批次的学校。造成滑档的常见原因有三类，第一种是因为高校调档比例较高，例如1:1.2比例调档，就会有超过招生计划的少部分的考生档案投递到该学校，由于招生计划不足使得考生不能被录取，然后考生被退档，只能参与下一批次的志愿填报；第二种是因为考生的填报问题，如院校志愿之间的梯度设置严重不合理等，导致的退档；第三种则是对志愿填报录取的规则不理解导致的滑档 。无论是哪种原因导致的的滑档都是极为可惜的，考生和家长要以此为戒，尽可能掌握志愿填报的基础规则！"
  },
  {
    id: 9,
    title: "志愿/专业级差",
    text: "志愿级差是指院校录取非第一志愿考生时的分数差额。例如，某高校规定的志愿级差为20分，第一志愿调档分数线为600分，那么，第二志愿的调档分数线就为620分。\n" +
      "专业级差是指录取非第一专业志愿时的分数差额。例如，某一院校规定专业级差为5分，院校在分配专业时，将第二专业志愿考生的成绩减去5分，将第三专业志愿考生的成绩减去10分……然后再排队确定专业。"
  },
  {
    id: 10,
    title: "提档分数线",
    text: "在同批录取的院校中，由于第一志愿报考人数不均衡，按照各院校招生计划、调档比例和考生成绩，各校的最低提档分数并不一样，这就自然地形成了院校的提档分数线。他们的共同特点一般是在同批录取控制分数线以上，而其不同特点是部分院校由于生源充足，其提档分数线高于同批控制分数线，有的院校由于生源不足，提档分数线就只好定到同批录取控制分数线，还有部分院校（专业）提档分数线低于同批录取控制分数线。因此，有的考生高考成绩虽然上了同批录取控制分数线，但由于未达到所报院校的提档分数线，电子档案不能投放，因而参加不了该校的录取，但也有部分学校由于生源不足，而低于某批控制分数线却被录取，就是由于降分的缘故。"
  },
  {
    id: 11,
    title: "省控线/校控线",
    text: "省控线和校控线都是由两部分组成——专业控线和文化控线。省控线可以理解为省批次线，过了相应批次的批次线才能填报相应批次的院校，否则即便是填报了也不会投档到相应的院校。校控线是考生比较容易忽视的分数，是院校层面对考生文化或者专业提出了更高的要求，如果达到省控线确未达到校控线则同样不会被院校录取。校控线一般大于等于省控线，例如央美的造型艺术专业就要求报考考生的文化分需要达到省一批（段）线的75%。"
  },
  {
    id: 12,
    title: "小分限制",
    text: "所谓小分限制就是院校对某些高考科目的具体分数要求，通常来说如果小分限制不达标，就算达到了院校的录取分数要求，也不会被录取，所以衡量自己能否被意向院校录取时一定要留心院校是否对小分有特殊要求。艺术类专业的小分限制主要集中在语文和英语两科，而且近年来的整体趋势是越来越多的院校逐步取消了小分限制，但考生们也不要掉以轻心，毕竟文化分要求提升同样是有目共睹的。"
  },
  {
    id: 13,
    title: "录取分数线",
    text: "分数线即是通过考试选拔一些合格者进一步进修或升学，而制定的被选拔的底限分数。录取分数线是根据当年招生计划和考生成绩，依据人数来划定。\n" +
      "      一般按略多于计划数划定，多数是计划数的1.1～1.2倍，全省考生按考分的高低排下来，排到该人数时的分数，就是当年该省的最低控制分数线，只有达到该分数的考生才有资格被录取。例：某省2003年理科计划招生人数为34780人，根据全省考生的高考成绩排队，如按1∶1.1比例划定的话，则从高分到低分排到第38258名，最后一位的高考分数为359分，则359就是该省理科最低分数线，也就是我们在报上见到的录取最低控制分数线。"
  },
  {
    id: 14,
    title: "大小年现象",
    text: "大小年现象，是指上年报考人数多录取分数高而使当年考生报考人数减少录取分数下降，如此循环往复呈现出录取分数“一年高一年低”的规律。录取分数高的年份成为“大年”，录取分数低的年份称之为“小年”，通常来讲，院校大小年相应录取数据的参考价值比较有限，若要参考需要格外慎重"
  },
  {
    id: 15,
    title: "征集志愿",
    text: "征集志愿是指在每一批次录取结束之后，省级招办将录取未满额的院校和余缺计划数向社会进行公布，供尚未被录取并达到分数要求的考生进行选报，填报征集志愿两大前提是院校有剩余计划、考生未被录取且分数达线。征集志愿的院校计划具有较大的随机性，但是如果能把握好征集志愿也相当于多了一个机会。"
  }
];

export default teachData;