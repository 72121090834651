var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"main-content"},[_vm._m(0),_c('div',{staticClass:"main-content-cardlist"},_vm._l((_vm.cardList),function(item,index){return _c('div',{key:item.id,staticClass:"main-content-card"},[_c('img',{attrs:{"src":item.imgs,"alt":""}}),_c('div',{staticClass:"main-content-card-text"},[_vm._v(_vm._s(item.text))])])}),0),_c('div',{staticClass:"main-content-question"},[_c('div',{staticClass:"main-content-question-progress"},[_c('div',{staticClass:"main-content-question-progress-text"},[_vm._v(" "+_vm._s(_vm.questionIndex)+"/"+_vm._s(_vm.questionList.length)+" ")]),_c('div',{staticClass:"main-content-question-progress-bar"}),_c('div',{staticClass:"main-content-question-progress-bar1",style:({
              width: `${(900 / _vm.questionList.length) * _vm.questionIndex}px`,
            })})]),_c('div',{staticClass:"main-content-question-main"},[_c('div',{staticClass:"main-content-question-main-title"},[_vm._v(" "+_vm._s(_vm.questionIndex)+"."+_vm._s(_vm.showList.question)+" ")]),_vm._l((_vm.showList.options),function(item){return _c('div',{key:item.optionId,class:item.optionValue === _vm.answerValue
                ? 'main-content-question-main-selected'
                : 'main-content-question-main-select',on:{"click":function($event){return _vm.selected(item, _vm.showList)}}},[_vm._v(" "+_vm._s(item.optionName)+" ")])})],2)]),_c('div',{staticClass:"main-content-buttons"},[_c('el-button',{staticClass:"main-content-buttons-last",attrs:{"disabled":_vm.questionIndex === 1 ? true : false},on:{"click":function($event){return _vm.lastQuestion()}}},[_vm._v(" 上一题 ")]),(_vm.questionIndex != _vm.questionList.length)?_c('el-button',{staticClass:"main-content-buttons-next",attrs:{"disabled":_vm.questionIndex === _vm.questionList.length ? true : false},on:{"click":function($event){return _vm.nextQuestion()}}},[_vm._v(" 下一题 ")]):_vm._e(),(_vm.questionIndex == _vm.questionList.length)?_c('el-button',{staticClass:"main-content-buttons-next",on:{"click":function($event){return _vm.submit()}}},[_vm._v("提交")]):_vm._e()],1),_vm._m(1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content-title"},[_c('h1',[_vm._v("MBTI性格测试")]),_c('div',{staticClass:"main-content-title-text"},[_vm._v(" 你是"),_c('span',[_vm._v("i")]),_vm._v("人还是"),_c('span',[_vm._v("e")]),_vm._v("人，坚持一下完成探索 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content-footer"},[_c('div',{staticClass:"main-content-footer-lab"},[_vm._v("完成测试后，您将获得")]),_c('div',{staticClass:"main-content-footer-list"},[_c('div',{staticClass:"main-content-footer-list-li"},[_vm._v(" ·获取您的字母类型测试结果 ")]),_c('div',{staticClass:"main-content-footer-list-li"},[_vm._v(" ·发现适合您性格类型的职业 ")])]),_c('div',{staticClass:"main-content-footer-list"},[_c('div',{staticClass:"main-content-footer-list-li"},[_vm._v(" ·知悉您的偏好优势和类型描述 ")]),_c('div',{staticClass:"main-content-footer-list-li"},[_vm._v(" ·了解您的沟通风格和学习风格 ")])])])
}]

export { render, staticRenderFns }