<template>
  <!-- 蒙版 -->
  <div class="open">
    <!-- 盒子 -->
    <div class="open-box">
      <div class="box-top">
        <i class="el-icon-close" @click="close()"></i>
        <div class="avatar">
          <img src="@/assets/images/openVip/02.png" alt="头像" />
        </div>
        <div class="info">
          <div class="phone">用户:{{ userName }}</div>
          <div class="lab">开通会员享受更多权益</div>
        </div>
        <div class="vip">VIP会员中心</div>
      </div>
      <div class="box-bottom">
        <div class="bottom-left">
          <div class="left-title">志愿VIP会员享受<span>8大权益</span></div>
          <div class="left">
            <div class="leb">
              <div class="img-box">
                <img src="@/assets/images/openVip/10.png" alt="" /><span
                  >智能填报</span
                >
              </div>
              <div class="img-box">
                <img src="@/assets/images/openVip/11.png" alt="" /><span
                  >测录取概率</span
                >
              </div>
              <div class="img-box">
                <img src="@/assets/images/openVip/12.png" alt="" /><span
                  >院校对比</span
                >
              </div>
              <div class="img-box">
                <img src="@/assets/images/openVip/13.png" alt="" /><span
                  >历年数据</span
                >
              </div>
              <div class="img-box">
                <img src="@/assets/images/openVip/14.png" alt="" /><span
                  >保稳冲推荐</span
                >
              </div>
              <div class="img-box">
                <img src="@/assets/images/openVip/15.png" alt="" /><span
                  >修改成绩</span
                >
              </div>
              <div class="img-box">
                <img src="@/assets/images/openVip/16.png" alt="" /><span
                  >测文化分</span
                >
              </div>
              <div class="img-box">
                <img src="@/assets/images/openVip/17.png" alt="" /><span
                  >导出志愿表</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-right">
          <div class="right-top">
            <img src="@/assets/images/openVip/组 5627.png" alt="" />
            <div class="card">志愿卡</div>
            <div class="title">艺考志愿VIP</div>
            <div class="year">
              <span style="font-size: 20px">￥</span>898/<span
                style="font-size: 30px"
                >年</span
              >
            </div>
          </div>
          <div class="right-bottom">
            <div class="bottom-top">
              <div class="top-weChat" @click="pay(1)">
                <img src="@/assets/images/openVip/组 5291.png" alt="" />微信支付
              </div>
              <!-- <div class="top-bao" @click="pay(2)">
                <img src="@/assets/images/openVip/组 5294.png" alt="" />支付宝支付
              </div> -->
            </div>
            <div class="bottom-middle" v-if="this.active == 1">
              <div class="middle-left">
                <div id="qrcode" ref="qrcode" class="qrcode-wrap"></div>
              </div>
              <div class="middle-right">
                <div class="span1">打开微信扫一扫</div>
                <div class="span2">有效期：使用天数为365天</div>
                <div class="span3"><span>同意并支付</span>￥898</div>
                <div class="span4">已阅读并同意<a>用户协议</a></div>
              </div>
            </div>
            <div class="bottom-middle" v-if="this.active == 2">
              <div class="middle-left">
                <!-- <div id="qrcode" ref="qrcode"></div> -->
              </div>
              <div class="middle-right">
                <div class="span1">打开支付宝扫一扫</div>
                <div class="span2">有效期：使用天数为365天</div>
                <div class="span3"><span>同意并支付</span>￥898</div>
                <div class="span4">已阅读并同意<a>用户协议</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { qrCode, orderStatus } from "@/api/index";
import QRCode from "qrcodejs2";
export default {
  name: "openVip",
  data() {
    return {
      active: 1,
      userName: "",
      orderId: "",
      imgUrl: "",
      timer: null,
    };
  },
  mounted() {
    this.getUser();
    this.getUrl();
  },
  methods: {
    // 获取支付页面二维码
    getUrl() {
      // this.creatQrcode();
      let data = {
        tradeType: "NATIVE",
      };
      qrCode(data).then((res) => {
        console.log(123, res);
        this.orderId = res.data.orderId;
        this.creatQrcode(res.data.info.codeUrl);
      });
    },
    creatQrcode(data) {
      let text = data;
      console.log('hjkhjklasdfasd',text);
      
      let qrcode = new QRCode(this.$refs.qrcode, {
        text: text,
        width: 128,
        height: 128,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.L,
      });
      this.createTimer();
    },
    // 查询订单状态
    getOrderStatus() {
      let data = {
        recordId: this.orderId,
      };
      orderStatus(data).then((res) => {
        console.log(res);
        if (res.data.orderState == 1) {
          // clearInterval(this.timer);
          this.close();
          this.$notify({
            title: "支付成功",
            message: "支付成功",
            type: "success",
            duration: 2000,
          });
        } else if (res.data.orderState == 1) {
          
        } else if (res.data.orderState == 2) {
          this.close();
          this.$notify({
            title: "支付失败",
            message: "支付失败",
            type: "error",
            duration: 2000,
          });
        }
      });
    },
    // 创建定时器
    createTimer() {
      this.timer = setInterval(() => {
        console.log(123);
        this.getOrderStatus();
      }, 1000);
    },
    // 获取用户
    getUser() {
      let userid = JSON.parse(localStorage.getItem("userList"))?.userName;
      console.log(userid);
      this.userName = userid;
    },
    close() {
      this.$store.commit("OPENVIP", 0);
      clearInterval(this.timer);
    },
    // 选择支付方式
    pay(type) {
      this.active = type;
      // this.creatQrcode();
    },
  },
};
</script>

<style lang="scss" scoped>
.open {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  // width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: auto;

  .open-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: 600px;
    width: 900px;
    // margin: 50px auto;
    background: url("../../../assets/images/openVip/组 5628.png") no-repeat;
    background-size: cover;
    overflow: hidden;
    .box-top {
      margin: 50px;
      display: flex;
      align-items: center;
      position: relative;
      .el-icon-close {
        position: absolute;
        top: -30px;
        right: -30px;
        cursor: pointer;
      }
      .avatar {
        img {
          border: 3px solid #ff93c5;
          border-radius: 50%;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        // align-items: center;
        margin-left: 10px;
        .phone {
          font-size: 22px;
          font-weight: 700;
        }
        .lab {
          font-size: 14px;
          background: #ffffff;
          padding: 5px 10px;
          border-radius: 20px;
          color: #acacac;
        }
      }
      .vip {
        font-size: 40px;
        margin-left: 50px;
      }
    }
    .box-bottom {
      margin: 0 50px;
      height: 400px;
      display: flex;
      .bottom-left {
        background: #fff;
        width: 315px;
        height: 100%;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0 0 10px #e9aa1c;
        .left-title {
          text-align: center;
          margin: 30px 0;
          font-size: 20px;
          span {
            color: #e9aa1c;
          }
        }
        .left {
          .leb {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;
            height: 300px;
            width: 315px;
            .img-box {
              width: 157px;
              height: 75px;
              position: relative;
              img {
                position: absolute;
                left: 10px;
              }
              span {
                position: absolute;
                left: 80px;
                font-size: 14px;
                line-height: 60px;
              }
            }
          }
        }
      }
      .bottom-right {
        width: 485px;
        .right-top {
          width: 465px;
          margin-left: 20px;
          position: relative;
          img {
            width: 100%;
          }
          .card {
            position: absolute;
            top: 7px;
            left: 10px;
            width: 100px;
            height: 40px;
            background: #e9aa1c;
            text-align: center;
            line-height: 40px;
            border-radius: 17px 20px 20px 0;
            color: #ffffff;
            font-size: 18px;
          }
          .title {
            position: absolute;
            top: 30px;
            left: 150px;
            font-size: 30px;
            color: #bb8206;
          }
          .year {
            position: absolute;
            top: 90px;
            left: 150px;
            font-size: 35px;
            color: #bb8206;
            font-weight: 700;
          }
        }
        .right-bottom {
          width: 455px;
          height: 220px;
          background: #ffffff;
          margin: 20px 0 20px 27px;
          border-radius: 20px;
          overflow: hidden;
          .bottom-top {
            display: flex;
            margin: 10px 20px;
            div {
              width: 200px;
              height: 45px;
              border-radius: 10px;
              display: flex;
              font-size: 20px;
              align-items: center;
              justify-content: center;
              img {
                margin-right: 10px;
              }
            }
            .top-weChat {
              background: #e9aa1c;
              margin-right: 20px;
            }
            .top-bao {
              background: #dbeeff;
            }
          }
          .bottom-middle {
            width: 455px;
            display: flex;
            .middle-left {
              width: 150px;
              height: 150px;
              border: 1px solid #e9aa1c;
              border-radius: 10px;
              margin-left: 20px;
              .qrcode-wrap {
                width: 130px;
                height: 130px;
                padding: 10px;
              }
              .qrcode-wrap canvas,
              .qrcode-wrap img {
                width: 100%;
                height: 100%;
              }
            }
            .middle-right {
              width: 280px;
              height: 150px;
              margin-left: 20px;
              .span1 {
                height: 30px;
                line-height: 30px;
                border-radius: 5px;
                width: 240px;
                padding-left: 10px;
                background: #feece4;
              }
              .span2 {
                height: 30px;
                line-height: 30px;
                border-radius: 5px;
                width: 240px;
                padding-left: 10px;
                background: #ececec;
                margin-top: 10px;
              }
              .span3 {
                margin-top: 20px;
                height: 40px;
                font-size: 30px;
                color: #d3136c;
                font-weight: 700;
                span {
                  color: black;
                  font-size: 24px;
                  font-weight: 100;
                }
              }
              // background: #acacac;
            }
          }
        }
      }
    }
  }
}
</style>