<template>
  <div class="arithmetic">
    <div class="title">录取查询</div>
    <div class="table">
      <el-form ref="form" :model="form" label-width="100px" label-position="left">
        <el-row justify="space-between">
          <el-col :span="12">
            <el-form-item label="姓名">
              <el-select v-model="form.name" placeholder="请选择活动区域" popper-class="sel_class" :popper-append-to-body="false">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="form_item_right">
            <el-form-item label="手机号">
              <el-select v-model="form.name" placeholder="请选择活动区域" :popper-append-to-body="false">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-between">
          <el-col :span="12">
            <el-form-item label="查询年份">
              <el-select v-model="form.name" placeholder="请选择活动区域" :popper-append-to-body="false">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-between">
          <el-col :span="12">
            <el-button :style="{backgroundColor: '#020D69'}">重置</el-button>
            <el-button>查询</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="el_table">
      <el-table :data="tableData2" style="width: 100%">
        <el-table-column
            prop="yearly"
            label="姓名"
            header-align="center"
            align="center"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="planNums"
            label="手机号"
            header-align="center"
            align="center"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="minComScore"
            label="首选科目"
            header-align="center"
            align="center"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="minComScore"
            label="次选科目"
            header-align="center"
            align="center"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="minComScore"
            label="高考年份"
            header-align="center"
            align="center"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="minComScore"
            label="成绩"
            header-align="center"
            align="center"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="minComScore"
            label="是否录入方案"
            header-align="center"
            align="center"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="minComScore"
            label="录取院校"
            header-align="center"
            align="center"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="minComScore"
            label="录取专业"
            header-align="center"
            align="center"
            width="150"
        >
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
export default {
  name: "AdmissionInquiry",
  data() {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      tableData2: [
        {yearly: 2021,planNums: '11111111111', minComScore: 12},
        {yearly: 2022,planNums: '10%', minComScore: 10},
        {yearly: 2022,planNums: '10%', minComScore: 10},
        {yearly: 2022,planNums: '10%', minComScore: 10},
      ],
    }
  },
  methods: {
    arithmeticForm() {
      console.log("fto--", this.form)
    },
  }
}
</script>

<style lang="scss" scoped>

.arithmetic {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;

  .title {
    font-family: Source Han Sans SC, Source Han Sans SC;
    //font-weight: bold;
    font-size: 22px;
    color: #242424;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 30px;
  }

  .table {

    ::v-deep .el-input__inner {
      width: 300px;
      height: 45px;
    }

    ::v-deep .el-form-item__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 18px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: #CC1D7A;
      background: #CC1D7A;
    }

    ::v-deep .el-radio__inner {
      width: 16px;
      height: 16px;
    }

    ::v-deep .el-color-picker__icon, .el-input, .el-textarea {
      width: 300px;
    }

    ::v-deep .el-radio__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .points {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #AEAEAE;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-right: 5px;
      line-height: 45px;
    }

    ::v-deep .el-input__inner {
      width: 300px;
      height: 45px;
    }

    ::v-deep .el-form-item__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep .el-button {
      width: 160px;
      height: 45px;
      border-radius: 10px;
      background-color: #CC1D7A;

      span {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

    }

    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: #CC1D7A;
      background: #CC1D7A;
    }

    ::v-deep .el-radio__inner {
      width: 16px;
      height: 16px;
    }

    ::v-deep .el-color-picker__icon, .el-input, .el-textarea {
      width: 300px;
    }

    ::v-deep .el-radio__label {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #373737;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    ::v-deep {

      .el-select-dropdown__item.selected{
        color: #CC1D7A;
      }

      el-input.is-focus .el-input__inner {
        border-color: #CC1D7A;
      }
      .el-input__inner:focus {
        border-color: #CC1D7A;
      }

    }

    .points {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #AEAEAE;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-right: 5px;
      line-height: 45px;
    }

    .form_item_right {
      display: flex;
      justify-content: right;

      .form_item_right_button {
        width: 300px;
      }

    }
  }

  .el_table {
    margin-top: 10px;

    ::v-deep .el-table__header > .has-gutter {
      color: #373737;
      font-size: 18px;
      font-weight: 500;
    }

  }

}

</style>
