<template>
  <div class="top-search-box">
    <div class="top-search-inner-box">
      <div class="left-box">
        <img
          class="text-image-box"
          src="@/assets/images/topSearch/textImage.png"
        />
        <img
          class="text-image-box"
          src="@/assets/images/topSearch/youyisheng.png"
        />
        <div class="text-image-title">全国艺术类志愿填报系统</div>
        <!--  <div class="line-box"></div>
        <div class="left-title-box">全国艺术类志愿填报系统</div> -->
        <div class="search">
          <el-input
            v-model="input"
            prefix-icon="search-icon"
            placeholder="找大学、查专业、搜资讯"
          >
            <template slot="prefix">
              <img
                src="@/assets/images/topSearch/search.png"
                alt="Custom Icon"
                class="search-icon"
              /> </template
          ></el-input>
        </div>
      </div>
      <div class="right-box">
        <div class="right-left">高考倒计时{{ countdownDays }}天</div>
        <div class="right-rig">
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="opvip()"
            class="flash-button"
          >
            <span style="height: 34px" v-if="!vip">开通</span
            ><span style="height: 34px; font-size: 24px; margin-left: 5px"
            v-if="!vip">VIP</span
            >
            <span style="height: 34px;font-size: 20px;" v-if="vip == 1">金榜题名</span></el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopSearch",
  data() {
    return {
      input: "",
      vip: 0,
    };
  },
  computed: {
    countdownDays() {
      const now = new Date();
      const currentYear = now.getFullYear();
      const examDate = new Date(currentYear, 5, 7); // 6月7日，月份从0开始计数
      // 如果当前日期已经过了今年的高考日期，则计算到下一年的高考
      if (now > examDate) {
        examDate.setFullYear(currentYear + 1);
      }
      const timeDiff = examDate.getTime() - now.getTime();
      const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
      return daysDiff;
    },
  },
  watch: {
    "$store.state.home.isVip": {
      handler(newValue, oldValue) {
        console.log(123);
        this.vip = newValue;
      },
      deep: true,
    },
  },
  mounted() {
    this.getVip();
  },
  methods: {
    getVip() {
      this.vip = this.$store.state.home.isVip;
    },
    opvip() {
      console.log(1234);

      if (this.vip == 0) {
        this.$store.commit("OPENVIP", 1);
      } 
    },
  },
};
</script>

<style scoped lang="scss">
//引入公共样式表
@import "@/assets/style/businessTheme.scss";

.top-search-box {
  width: 100%;
  box-sizing: border-box;
  padding-left: $page-content-padding-left;
  display: flex;
  justify-content: center;
  align-items: center;

  .top-search-inner-box {
    width: $page-content-width;
    height: 60px;
    display: flex;
    align-items: center;
    //background-color: pink; //做完要去掉这个背景
    user-select: none;
    .left-box {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .text-image-box {
        height: 40px;
        object-fit: cover;
        margin-right: 5px;
      }

      .text-image-title {
        height: 30px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #4E4D4D;
        text-align: left;
        font-style: normal;
        line-height: 30px;
        text-transform: none;
        border-left: 1px solid #707070;
        padding-left: 10px;
        margin-left: 7px;
      }

      .left-text-box {
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        .left-top-text {
          line-height: 36px;
          font-weight: 500;
          font-size: 36px;
          color: #2397fe;
        }

        .left-bottom-text {
          line-height: 8px;
          font-size: 8px;
          color: #777777;
        }
      }

      .line-box {
        width: 1px;
        height: 35px;
        background-color: #707070;
        margin: 0 15px;
      }

      .left-title-box {
        // font-weight: 400;
        font-size: 22px;
        color: #4e4d4d;
        letter-spacing: 1px;
      }
      .search {
        margin-left: 35px;
        width: 254px;
        //margin: 0 auto;

        ::v-deep .el-input__inner {
          background: #f5f5f5;
          border: $page-main-color solid 1px;
          padding-left: 40px;
          border-radius: 10px;
        }
        .search-icon {
          margin: 10px 8px 10px 8px;
        }
      }
    }
    .right-box {
      display: flex;
      .right-left {
        width: 130px;
        height: 38px;
        border: 1px solid #E32930;
        font-size: 14px;
        color: #E32930;
        text-align: center;
        line-height: 38px;
        border-radius: 10px 0 0 10px;
        z-index: 1;
      }
      .right-rig {
        ::v-deep .el-button {
          background: #E32930;
          // border: #9b0688 solid 1px;
          width: 130px;
          border: 0;
          height: 40px;
          border-radius: 0 10px 10px 0;
          display: flex;
          align-items: center;
          position: relative;
          z-index: 1;
          span {
            display: flex;
            align-items: center;
          }
          .el-icon-plus {
            background: url("@/assets/images/topSearch/vip.png") center
              no-repeat;
            font-size: 14px;
            display: flex;
            align-items: center;
            // margin-top: -10px;
          }
          .el-icon-plus:before {
            visibility: hidden;
          }
        }
        .el-button:before {
          content: "";
          z-index: 1;
          position: absolute;
          width: 130px;
          height: 100%;
          background-image: linear-gradient(
            120deg,
            rgba(255, 255, 255, 0) 60%,
            rgba(255, 255, 255, 0.4),
            rgba(255, 255, 255, 0) 80%
          );
          top: 0;
          left: 0px;
          animation: blink-animation 3s linear forwards infinite;
        }
        @keyframes blink-animation {
          0% {
            left: -100px;
            top: 0;
          }
          20% {
            left: 55px;
            top: 0;
          }
          100% {
            left: 55px;
            top: -10000px;
          }
        }
      }
    }
  }
}
</style>