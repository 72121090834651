import _ from "lodash";
import PubSub from 'pubsub-js'
import {verifyValue} from "./verify";
import {Message} from "element-ui";

/**
 * 获取url参数
 * @param name          参数名
 * @returns {string}
 * @constructor
 */
export function GetQueryString(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.href.split("?")[1]?.match(reg); //获取url中"?"符后的字符串并正则匹配
  let context = "";
  if (r != null) context = decodeURIComponent(r[2]);
  reg = null;
  r = null;
  return context == null || context == "" || context == "undefined"
    ? ""
    : context;
}

/**
 * 树形数据转换
 * @param data        数据源
 * @param id          id的字段名
 * @param parentId    父级id的字段名
 * @param children    子级字段名
 * @returns {*[]}     返回的树形数据
 */
export function handleTree(data, id, parentId, children) {
  let config = {
    id: id || "id",
    parentId: parentId || "parentId",
    childrenList: children || "children",
  };

  let childrenListMap = {};
  let nodeIds = {};
  let tree = [];

  for (let d of data) {
    let parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }

  for (let d of data) {
    let parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }

  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }

  return tree;
}

/**
 * 监听dom元素大小变化
 * @param dom          监听的dom元素
 * @param callback     回调函数
 */
export function resizeObserver(dom, callback) {
  let ro = new ResizeObserver(_.throttle(entries => {
    callback && callback(entries)
  }, 100))
  ro.observe(dom)
}

/**
 * 创建dom元素
 * @param type          元素类型
 * @param classList     类名
 * @param childList     子元素
 * @returns
 */
export function createdElement(type, classList, childList) {
  let dom = document.createElement(type);
  if (Array.isArray(classList) && classList?.length) {
    _.forEach(classList, (className, index) => {
      dom.classList.add(className)
    })
  }
  if (Array.isArray(childList) && childList?.length) {
    _.forEach(childList, (child, index) => {
      dom.appendChild(child)
    })
  }
  return dom
}

/**
 * 获取文件图标
 * @param fileName     文件名
 * @returns {*}
 */
// export function getFileIconByFileName(fileName) {
//   let baseImg = require('../assets/images/fileIcon/ZIP.png')
//   if (fileName == null || fileName == '') {
//     return baseImg
//   }
//   let nameList = fileName.split('.')
//   let img = '';
//   try {
//     img = require(`../assets/images/fileIcon/${nameList[nameList.length - 1].toUpperCase()}.png`)
//   } catch (e) {
//     img = baseImg
//   }
//   return img
// }

/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
  let result = '';
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    if (value !== null && value !== "" && typeof value !== "undefined") {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (item !== null && item !== "" && typeof item !== "undefined") {
            let params = `${propName}`;
            result += encodeURIComponent(params) + "=" + encodeURIComponent(item) + "&";
          }
        });
      } else if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== "" && typeof value[key] !== 'undefined') {
            let params = `${propName}[${key}]`;
            result += encodeURIComponent(params) + "=" + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += encodeURIComponent(propName) + "=" + encodeURIComponent(value) + "&";
      }
    }
  }
  return result; // 去掉最后一个多余的 &
}

/**
 * 下载文件
 * @param fileUrl   文件全路径
 */
export function downloadFileByUrl(fileUrl) {
  if (!verifyValue(fileUrl)) {
    Message.error('下载路径不存在');
    return
  }
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileUrl.split('/').pop(); // 获取文件名
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * 刷新浏览器页面
 */
export function reloadPage() {
  window.location.reload()
}

// 数据合并
export function mergeRecursive(source, target) {
  for (var p in target) {
    try {
      if (target[p].constructor == Object) {
        source[p] = mergeRecursive(source[p], target[p])
      } else {
        source[p] = target[p]
      }
    } catch (e) {
      source[p] = target[p]
    }
  }
  return source
}

export default PubSub
