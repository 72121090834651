<template>
  <div class="privacy">
    <div class="txt">
      <h3>隐私政策</h3>
      <p>生效日期：2020年11月15日。</p>
      <p>
        欢迎您使用优艺升-弈升生涯艺术版平台，优艺升-弈升生涯艺术版平台（以下简称“优艺升-弈升生涯艺术版”）是杭州绿皮书教育科技有限公司管理，优艺升-弈升生涯艺术版对每一位用户的隐私权采取高度尊重且保护的态度。
        本政策适用于我们的所有产品及服务（包括但不限于 优艺升-弈升生涯艺术版
        学生端APP、优艺升-弈升生涯艺术版 学校管理后台、优艺升-弈升生涯艺术版小助手
        APP等）。您在使用优艺升-弈升生涯艺术版的产品及服务时，优艺升-弈升生涯艺术版可能会收集和使用您的相关信息。为此，希望通过本《隐私政策》向您说明，优艺升-弈升生涯艺术版如何取得、使用、分享、保存和管理这些信息。
        请在使用我们的各项产品与服务前，仔细阅读并充分理解本政策，并在需要时，按照本政策的指引，作出适当的选择。
        一旦你开始使用 优艺升-弈升生涯艺术版
        的各项服务，即表示您充分理解和同意本《隐私政策》（包括更新版本）的全部内容。
      </p>
      <p class="black">一、我们收集哪些类型的信息</p>
      <p>
        为了向你和学校用户提供服务、优化我们的服务以及保障帐号安全，优艺升-弈升生涯艺术版
        会按照如下方式收集你在注册、使用服务时主动提供、授权或因为使用服务而产生的信息：
      </p>
      <p>（一）帐号注册信息</p>
      <p>
        当你首次注册登录 优艺升-弈升生涯艺术版，你需要提供你的手机号，上述信息为你使用
        优艺升-弈升生涯艺术版
        所必需，若你不提供这类信息，你将无法正常使用我们的服务。以及你向我们提供其他人的个人信息时，你必须获得该人士的同意。
      </p>
      <p>（二）使用 优艺升-弈升生涯艺术版 服务</p>
      <p>
        为满足业务需求，我们需要认证您的身份相关信息，这些信息包括身份证、学籍信息、人脸拍照，用于学生报名时或考试前身份确认。为便于诊断程序问题，我们将记录运行时的错误日志，日志中确保没有用户隐私相关的信息。
        为维护服务的正常运行，向你提供 优艺升-弈升生涯艺术版助手
        产品及服务，我们需要获取以下权限：
      </p>
      <p>1. 拨打电话方便用户联系客服；</p>
      <p>
        2.
        拍摄照片和录制视频用于用户考试时视频录制、用户身份认证以及用户个人头像上传；录制音频用于用户考试时音频录制；
      </p>
      <p>3. 记录用户在录制音视频的运行日志，以提供视频质量分析。</p>
      <p>4. 修改或删除您的SD卡中的内容用于用户头像上传和apk下载更新；</p>
      <p>5. 读取您的SD卡中的内容用于获取SD卡剩余状态以及app相关的文件存储；</p>
      <p>6. 引导用户打开通知（摄像头、拨打电话、麦克风）的权限；</p>
      <p>
        7.
        如用户需要接受系统推送，则需要允许本App记录设备IMSI、IMEI号，以便系统进行推送，如不授权本权限，则会导致无法正常接受系统推送消息；
      </p>
      <p>
        8.
        设备MAC信息，App应用前台及后台服务时均会读取舍内的MAC信息，用于生成设备唯一标识码；
      </p>
      <p>
        请你理解，我们向你提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且需要收集你的信息，我们会通过页面提示、交互流程、网站公告等方式另行向你说明信息收集的内容、范围和目的，以征得你的同意。
        如你选择不提供上述信息，你可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。
      </p>
      <p class="black">二、我们如何共享、转让、公开披露您的个人信息</p>
      <p>
        （一）共享
        尊重用户个人隐私是我们提供产品和服务的一项基本原则。除以下情形外，我们不会与任何公司、组织和个人分享你的个人信息：
      </p>
      <p>
        1.在获取明确同意的情况下共享：获得你的明确同意后，我们会与其他方共享你的个人信息。
      </p>
      <p>2.我们可能会按照司法机关或行政机关的要求，对外共享你的个人信息。</p>
      <p>
        3.与我们的关联公司共享：你的个人信息可能会与 杭州绿皮书教育科技有限公司
        的关联公司共享。我们只会共享必要的个人信息，且受本政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求你的授权同意。
      </p>
      <p>
        4.与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享你的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享你的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
      </p>
      <p>
        对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政以及其他任何相关的保密和安全措施来处理个人信息。
      </p>
      <p>（二）转让</p>
      <p>我们不会将你的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
      <p>
        1.在获取明确同意的情况下转让：获得你的明确同意后，我们会向其他方转让你的个人信息。
      </p>
      <p>
        2.在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有你个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向你征求授权同意。
      </p>
      <p>（三）公开披露</p>
      <p>我们仅会在以下情况下，公开披露你的个人信息：</p>
      <p>1.获得你明确同意后。</p>
      <p>
        2.基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露你的个人信息。
      </p>
      <p>(四) 共享、转让、公开披露个人信息时事先征得授权同意的例外：</p>
      <p>1. 与个人信息控制者履行法律法规规定的义务相关的；</p>
      <p>2. 与国家安全、国防安全直接相关的；</p>
      <p>3. 与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>4. 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>
        5.
        出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
      </p>
      <p>6. 个人信息主体自行向社会公众公开的个人信息；</p>
      <p>
        7.
        从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      </p>
      <p>
        (五)
        根据法律规定，共享、转让、披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理无需另行征得您的同意。
      </p>
      <p class="black">三、我们如何保护你的个人信息</p>
      <p>
        我们非常重视个人信息安全，并会采取一切合理可行的措施，持续保护您的个人信息，以防其他人在未经授权的情况下访问、篡改或披露
        优艺升-弈升生涯艺术版 收集的您的个人信息：
        我们已采用符合行业标准的安全防护措施来保护您的个人信息，防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，
        保护您的个人信息。例如，在您的移动设备与 优艺升-弈升生涯艺术版
        产品和服务之间交换数据时进行SSL加密保护；我们会使用受信赖的保护机制防止数据遭到恶意攻击。
        我们仅允许有必要知晓的人员访问相关个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。
        如果未能履行这些义务，可能会被追究法律责任或被终止与 优艺升-弈升生涯艺术版
        的相应法律关系。 我们会采取一切合理可行的措施，确保未收集无关的信息。
        您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便我们竭尽所能加强安全措施，但也不可能始终保证信息百分之百的绝对安全。
        您需要了解和知悉，您接入 优艺升-弈升生涯艺术版
        的产品及和服务所用的系统和通讯网络，有可能因我们可控范围外的其他因素而出现问题，在此情形下，我们会依法尽力协助解决。
        如不幸发生信息安全事故，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低
        风险的建议等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等适合的方式告知您；难以逐一告知信息主体时，我们会采取合理、
        有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。
      </p>
      <p class="black">四、如何保存您的个人信息</p>
      <p>(一) 信息保存的期限</p>
      <p>
        一般而言，我们仅为实现目的所必需的时间保留你的个人信息。我们不会查看或使用你留存的用户、文件等信息。
        当我们的产品或服务发生停止运营的情形时，我们将以推送通知、公告等形式通知你，在合理的期限内删除你的个人信息或进行匿名化处理，并立即停止收集个人信息的活动，以及关闭第三方应用服务接口，避免第三方服务收集及继续使用个人信息。
      </p>
      <p>(二) 信息保存的地点</p>
      <p>
        您的个人信息将全部被存储于中华人民共和国境内，目前我们不存在向境外提供个人信息的场景。
      </p>
      <p>(三)账户删除申请</p>
      <p>
        我们将尽量采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障账户安全。
      </p>
      <p>(四)账户注销</p>
      <p>
        当用户需要注销本账号时，可发送邮件至“lps@easywish.cn”， 优艺升-弈升生涯艺术版
        客服人员会在1-2个工作日进行验证注销；或者直接在我的=》设置=》注销账户，用户自己进行注销
      </p>
      <p class="black">五、我们可能向你发送的邮件和信息</p>
      <p>(一) 信息推送</p>
      <p>
        你在使用我们的服务时，我们可能使用你的信息向你发送产品调查问卷、满意度调查，以及通知学校面试申请开始的信息。如你不希望收到这些信息，可以按照我们的相关提示选择取消订阅。
      </p>
      <p>(二) 与服务有关的公告</p>
      <p>
        我们可能会发送某些与服务相关的信件，例如欢迎函、付费通知、技术服务问题信息、安全通知、因系统维护而暂停某一项服务的公告等与服务有关的公告。你可能无法取消这些与服务有关、性质不属于推广的公告。
      </p>
      <p class="black">六、免责</p>
      <p>下述情形我们亦无须承担任何责任：</p>
      <p>(一) 密码泄露</p>
      <p>由于你将用户信息告知他人或与他人共享注册账户，由此导致的任何个人资料泄露；</p>
      <p>(二) 不可抗力攻击</p>
      <p>任何由于计算机问题、黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料泄露、丢失、被盗用或被窜改等；</p>
      <p>(三) 第三方泄露</p>
      <p>由于与本网站链接的其它第三方提供的服务（包括网站）所造成之个人资料泄露及由此而导致的任何法律争议和后果。</p>
      <p class="black">七、本政策的变更</p>
      <p>本政策将不定时更新。未经您的明确同意，我们不会削减你按照本政策所应享有的权利。当你在政策的条例发生变更后继续使用我们的服务，即表示同意接受经修订的本《隐私政策》的约束。如果你不同意更新后的隐私政策，你必须停止访问或使用本平台。 如无特殊说明，修订后的《隐私政策》自公布之日起生效。</p>
      <p class="black">八、与我们联系</p>
      <p>当你有其他的投诉、建议、未成年人个人信息相关问题时，你可以将你的问题发送至lps@easywish.cn，我们将尽快审核所涉问题，并在验证你的用户身份后的七天内予以回复</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.privacy {
  width: 1280px;
  /* height: 100vh; */
  background: #f7f7f7;
  margin: 30px auto;

  .txt {
    width: 880px;
    padding: 30px 200px;
    background: #ffffff;
    h3 {
      margin-bottom: 20px;
      text-align: center;
    }
    p {
      font-family: 宋体;
      text-indent: 2em;
      margin-bottom: 15px;
      line-height: 150%;      
    }
    .black {
        color: #0d0d0d;
        font-weight: bolder;
        font-size: 20px;
      }
  }
}
</style>