import personalCenter from '@/views/PersonalCenter'
import myVolunteer from '@/views/PersonalCenter/MyVolunteer'
import detail from '@/views/PersonalCenter/Detail/index'
import collegeDetail from '@/views/FindCollege/CollegeDetail/index'
import majorDetail from '@/views/QuerySpeciality/majorDetail/index.vue'
import EnrollDetail from "@/components/Enrolldetail"
import Mbti from '@/views/QuestionNaire/Mbti'
import answer from '@/views/QuestionNaire/Mbti/answer'
import InformationList from "@/components/InformationList/index.vue"
import SimplifyTools from "@/views/SimplifyTools";

const personalCenterRoutes = [
    {
        path: 'personalCenter',
        name: 'personalCenter',
        component: personalCenter,
        meta: {
            requiresAuth: true,
            title: '个人中心'
        }
        ,
    },
    {
        path: 'myVolunteer',
        name: 'myVolunteer',
        component: myVolunteer,
        meta: {
            requiresAuth: true,
            title: '我的志愿'
        }
        ,
    },
    {
        path: 'detail',
        name: 'detail',
        component: detail,
        meta: {
            requiresAuth: true,
            title: '志愿详情'
        }
        ,
    }, {
        path: 'collegedetail',
        name: 'collegedetail',
        component: collegeDetail,
        meta: {
            requiresAuth: false,
            title: '院校详情'
        }
    },
    {
        path: 'majordetail',
        name: 'majordetail',
        component: majorDetail,
        meta: {
            requiresAuth: false,
            title: '专业详情'
        }
    }
    ,
    {
        path: 'Enrolldetail',
        name: 'enrolldetail',
        component: EnrollDetail,
        meta: {
            requiresAuth: false,
            title: '咨询详情'
        }
    },
    {
        path: 'mbti',
        name: 'mbti',
        component: Mbti,
        meta: {
            requiresAuth: false,
            title: 'MBTI测试'
        }
    }, {
        path: 'mbtiAnswer',
        name: 'mbtiAnswer',
        component: answer,
        meta: {
            requiresAuth: false,
            title: 'MBTI测试答案'
        },

    },
    {
        path: 'InformationList',
        name: 'informationList',
        component: InformationList,
        meta: {
            requiresAuth: false,
            title: '咨询列表'
        }
    },
    {
      path:'simplifytools',
      name:'simplifytools',
      component:SimplifyTools,
      meta:{
          requiresAuth: false,
          title:'艺考小工具'
      }
  },
]

export default personalCenterRoutes
