import _ from "lodash";
import {verifyValue} from "./verify";

/**
 * 获取随机数
 * @param min             最小值
 * @param max             最大值
 * @returns {number|*}    返回一个随机数
 */
const getRandom = (min = 0, max = 1, floating = false) => _.random(min, max, floating)

/**
 * 获取整数
 * @param val
 * @returns {number|number}
 */
const getIntNumber = val => verifyValue(val) && !isNaN(parseInt(val)) ? parseInt(val) : 0;

/**
 * 获取浮点数
 */
const getFloatNumber = val => verifyValue(val) && !isNaN(parseFloat(val)) ? parseFloat(val) : 0;


/**
 * 数组最大值
 * @param arr
 * @returns {unknown}
 */
const max = arr => _.max(arr)

/**
 * 数组最小值
 * @param arr
 * @returns {unknown}
 */
const min = arr => _.min(arr)

/**
 * 数组平均值
 * @param arr
 * @returns {number}
 */
const mean = arr => _.mean(arr)

/**
 * 四舍五入
 * @param value         需要处理的数字
 * @param precision     小数点位数
 * @returns {number}
 */
const round = (value, precision = 0) => _.round(value, precision)

/**
 * 数组求和
 * @param arr
 * @returns {number}
 */
const sum = arr => _.sum(arr)

export {getRandom, getIntNumber, getFloatNumber, max, min, mean, round, sum}
